import { Box, Chip, Stack, Typography, useTheme } from '@mui/material';
import Icon from 'components/DesignSystem/Icon';
import { noop } from 'lodash';
import DistributionCard from './DistributionCard';

const ScenarioTabs = ({
  handleAddClick,
  generics = {},
  filteredUsers = [],
  mappings = [],
  setMappings = noop,
  setDistributionError = noop,
  selectedScenarioTab = 0,
  setSelectedScenarioTab = noop,
  selectedScenario = {},
  handleOnResetClick = noop,
  deleteScenario = noop,
}) => {
  const theme = useTheme();

  const handleChipClick = (index) => {
    setDistributionError(false);
    setSelectedScenarioTab(index);
  };

  return (
    <Box sx={{ padding: '16px 0' }}>
      {/* Chips for each scenario */}
      <Stack direction="row" spacing={1} justifyContent={'space-between'}>
        <Box>
          <Chip
            label={
              <Typography variant="bodyText4" color={theme.palette.grey[600]}>
                Add +
              </Typography>
            }
            onClick={() => {
              // Logic to add a new scenario
              handleAddClick();
            }}
            sx={{
              borderColor: 'transparent',
              borderWidth: '1px',
              borderStyle: 'solid',
              backgroundColor: '#F5F5F5',
              color: '#707070',
              fontWeight: 'normal',
              padding: '0 4px',
              marginRight: '5px',
            }}
          />
          {generics?.scenarios?.map((scenario, index) => (
            <Chip
              key={index}
              label={<Typography variant="bodyText4">{`Scenario ${index + 1}`}</Typography>}
              onClick={() => handleChipClick(index)}
              sx={{
                borderColor: selectedScenarioTab === index ? theme.palette.primary.main : 'transparent',
                borderWidth: selectedScenarioTab === index ? '1px' : '0px',
                borderStyle: 'solid',
                backgroundColor: selectedScenarioTab === index ? '#FFF3EA' : '#F5F5F5',
                color: selectedScenarioTab === index ? theme.palette.primary.main : '#707070',
                fontWeight: selectedScenarioTab === index ? 'bold' : 'normal',
                margin: '0 5px',
              }}
            />
          ))}
        </Box>
        {generics?.scenarios?.length > 0 && (
          <Box onClick={deleteScenario}>
            <Icon name="delete-icon-orange-pink-bg.png" size={27} />
          </Box>
        )}
      </Stack>
      {generics?.scenarios?.length > 0 && (
        <>
          <Box p={'20px 0'}>
            <Typography variant="bodyText3" color={theme.palette.grey[400]}>
              In case of demise(s) of
              <Typography variant="bodyText3" color={theme.palette.grey[600]}>
                {selectedScenario?.deaths?.length > 0
                  ? `${selectedScenario?.deaths
                    .map((beneId) => {
                      const bene = generics?.beneficiary?.find((bene) => bene.id === beneId);
                      return ` ${bene.name} (${bene.relation})`;
                    })
                    .join(' and ')}, `
                  : ''}
              </Typography>
              how would you like to distribute your assets?
            </Typography>
          </Box>
          <Box>
            {filteredUsers?.map((row, index) => {
              return (
                <DistributionCard
                  asset={row}
                  key={index}
                  mappings={mappings}
                  handleOnResetClick={handleOnResetClick}
                  generics={generics}
                  setMappings={setMappings}
                  selectedScenario={selectedScenario}
                />
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ScenarioTabs;
