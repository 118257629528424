import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import dayjs from 'dayjs';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getCorrectCase } from 'utils/formatString';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../components/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'edit-action' },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'profile.partnerType', label: 'Type', alignRight: false },
  { id: 'partner_code', label: 'Partner Code', alignRight: false },
  { id: 'profile.companyName', label: 'Company', alignRight: false },
  { id: 'commission', label: 'Commission', alignRight: false },
  { id: 'discount', label: 'Coupon Discount', alignRight: false },
  { id: 'applyType', label: 'Apply Coupon Discount', alignRight: false },
  { id: 'xCustomers', label: 'For X Individuals', alignRight: false },
  { id: 'endDate', label: 'Till X Date', alignRight: false },
  { id: 'partner_enroll_requested_on', label: 'Enrollment Requested', alignRight: false },
  { id: 'partner_enroll_status', label: 'Enrollment Status', alignRight: false },
  // { id: "nominee", label: "Nominee", alignRight: false, alignCenter: true },
  { id: 'action' },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  console.log('descendingComparator', orderBy, a);
  if (orderBy === 'profile.companyName') {
    if (b.profile.companyName < a.profile.companyName) {
      return -1;
    }
    if (b.profile.companyName > a.profile.companyName) {
      return 1;
    }
  } else if (['discount', 'commission', 'applyType', 'xCustomers', 'endDate'].includes(orderBy)) {
    if (a.PartnerCommissions.length === 0) return 1;
    if (b.PartnerCommissions.length === 0) return -1;
    if (b?.PartnerCommissions[0][orderBy] < a?.PartnerCommissions[0][orderBy]) {
      return -1;
    }
    if (b?.PartnerCommissions[0][orderBy] > a?.PartnerCommissions[0][orderBy]) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    console.log('filter', JSON.stringify(array[0] ?? {}).toLowerCase());
    return filter(
      array,
      (_user) =>
        JSON.stringify(_user ?? {})
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PartnersPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('enrollStatus');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [partners, setPartners] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);

  const [schemas, setSchemas] = useState({});

  const [addNew, setAddNew] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [assetData, setAssetData] = useState({});
  const [errors, setErrors] = useState({});
  const [type, setType] = useState('');
  const [subType, setSubType] = useState('');
  const [reorderedArray, setReorderedArray] = useState('');
  const [discount, setDiscount] = useState(0);
  const [xCustomers, setXCustomers] = useState('');
  const [commission, setCommission] = useState(0);
  const [rcmAdvocate, setRCMAdvocate] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [applyType, setApplyType] = useState('');
  const [enrollStatus, setEnrollStatus] = useState('');
  const [approveStatus, setApproveStatus] = useState(false);
  const [endDateError, setEndDteError] = useState(null);
  const [isFormatValid, setIsFormatValid] = useState(true);
  const location = useLocation();
  const isFromAdminDashboard = location.state?.fromAdminDashboard;
  const navigate = useNavigate();

  const fetchPartners = () => {
    axios
      .get('/partners')
      .then((response) => {
        // Custom sorting function
        const customSort = (a, b) => {
          if (a.is_approved === true && b.is_approved !== true) {
            return 1; // Move a to the end
          }
          if (a.is_approved !== true && b.is_approved === true) {
            return -1; // Move b to the end
          }
          return 0; // Maintain the order
        };

        // Sort the array using the custom sorting function
        // const sortedArray = response.data?.partners.slice().sort(customSort);
        setPartners(response.data?.partners ?? []);
      })
      .catch((err) => console.error(err));
  };

  const fetchBeneficiaries = () => {
    axios
      .get('/generics/beneficiary')
      .then((response) => {
        setBeneficiaries(response.data?.generics ?? []);
      })
      .catch((err) => console.error(err));
  };

  const fetchForms = () => {
    axios
      .get('/properties/forms')
      .then((response) => {
        if (response.data) {
          // Convert the object into an array of objects
          const assetArray = Object.entries(response.data.schemas).map(([label, value]) => ({ label, value }));
          // Move "Other Assets" option to the end
          const tempReorderedArray = [
            ...assetArray.filter((option) => option.label !== 'other_assets'),
            assetArray.find((option) => option.label === 'other_assets'),
          ];
          setReorderedArray(tempReorderedArray);
          // console.log('reorderedArray',reorderedArray);
        }

        setSchemas(response.data ?? {});
      })
      .catch((err) => console.error(err));
  };
  const addNewAsset = () => {
    axios
      .post('/properties', { type, subType, asset: assetData })
      .then((response) => {
        // toastr.success("Asset saved successfully");
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          color: '#fff',
          background: '#00838F',
          toast: true,
          title: `Asset saved successfully`,
          showConfirmButton: false,
          timer: 3000,
        });
        fetchPartners();
        setAssetData({});
      })
      .catch((err) => {
        // toastr.error("Failed to add asset")
        Swal.fire({
          position: 'top-center',
          icon: 'error',
          color: '#fff',
          background: '#00838F',
          toast: true,
          title: `Failed to add asset`,
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const editPartner = () => {
    axios
      .post(`/partners/${editingId}`, {
        commission,
        discount,
        enrollStatus,
        applyType,
        xCustomers,
        endDate,
        approveStatus,
        rcmAdvocate,
      })
      .then((response) => {
        // toastr.success("Partner updated successfully");
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          color: '#fff',
          background: '#00838F',
          toast: true,
          title: `Partner updated successfully`,
          showConfirmButton: false,
          timer: 3000,
        });
        fetchPartners();
        setEdit(false);
        setOpen(false);
        setEditingId(null);
      })
      .catch((err) => {
        // toastr.error("Failed to update asset");
        Swal.fire({
          position: 'top-center',
          icon: 'error',
          color: '#fff',
          background: '#00838F',
          toast: true,
          title: `Failed to update partner`,
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const deletePartner = (id) => {
    axios
      .delete(`/testator/account`)
      .then((response) => {
        // toastr.success("Partner deleted successfully");
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          color: '#fff',
          background: '#00838F',
          toast: true,
          title: `Partner deleted successfully`,
          showConfirmButton: false,
          timer: 3000,
        });
        fetchPartners();
        setAssetData({});
      })
      .catch((err) => {
        // toastr.error("Failed to delete partner")
        Swal.fire({
          position: 'top-center',
          icon: 'error',
          color: '#fff',
          background: '#00838F',
          toast: true,
          title: `Failed to delete partner`,
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setEditingId(row.id);

    setApproveStatus(row.is_approved);
    setEnrollStatus(row.partner_enroll_status);
    setRCMAdvocate(row.rcmAdvocate);
    if (row.PartnerCommissions.length > 0) {
      setCommission(row.PartnerCommissions[0].commission);
      setDiscount(row.PartnerCommissions[0].discount);
      setApplyType(row.PartnerCommissions[0].applyType);
      setXCustomers(row.PartnerCommissions[0].xCustomers);
      setEndDate(row.PartnerCommissions[0].endDate);
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setApproveStatus(false);
    setCommission(0);
    setDiscount(0);
    setApplyType('');
    setXCustomers('');
    setEndDate('');
    setEditingId(null);
    setEdit(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = partners.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - partners.length) : 0;

  const filteredUsers = applySortFilter(partners, getComparator(order, orderBy), filterName);
  // console.log('filteredUsers',filteredUsers);
  const isNotFound = !filteredUsers.length && !!filterName;

  const steps = [
    {
      target: '#new-generic',
      content: `Click to add new asset`,
    },
    {
      target: 'body',
      content: `Fill in the data for asset`,
    },
    {
      target: '#new-generic-button',
      content: 'Click the save button',
    },
  ];
  const handleJoyrideCallback = (data) => {
    const { index, action, size } = data;
    if (action === 'next' && index === 0) {
      setAddNew(true);
    } else if (action === 'next' && index === size - 1) {
      setAddNew(false);
    }
  };

  //   useEffect(() => {
  //     const handleEnter = (e) => {
  //       if (e.code?.toLowerCase() === "enter" && !e.repeat) {
  //         e.preventDefault();
  //         if (errors?.length === 0) {
  //           if (editingId) editPartner();
  //           else addNewAsset();
  //         }
  //       }
  //     };
  //     window.addEventListener("keyup", handleEnter);
  //     return () => {
  //       window.removeEventListener("keyup", handleEnter);
  //     };
  //   }, [editingId, errors]);

  // const { isLoading, setShowHelp, editProfile } = useContext(LoadingContext);

  // useEffect(() => {
  //   if (!sessionStorage.getItem(`video_close_assets`) && !isLoading && partners.length === 0) {
  //     setShowHelp(true);
  //   } else {
  //     setShowHelp(false);
  //   }
  // }, [isLoading, isLoading, partners]);

  const convertPhoneNumber = (input) => {
    // Split the input string by ":"
    if (input && input.indexOf(':') !== -1) {
      const parts = input.split(':');

      // Check if there are at least three parts (including the "+" sign)
      if (parts.length >= 3) {
        // Extract the "in" and "91" values
        const countryCode = parts[1];
        const phoneNumber = parts[2];

        // Construct the formatted phone number
        const formattedPhoneNumber = `+${countryCode}-${phoneNumber}`;

        return formattedPhoneNumber;
      }
    }

    // Return the original input if it doesn't match the expected format
    return input;
  };

  const validateDateFormat = (dateString) => {
    // Implement your custom validation logic here
    const regex = /^\d{2}-\d{2}-\d{4}$/;
    return regex.test(dateString);
  };

  const handleBackClick = () => {
    navigate('/admin/analyticaldashboard');
  };

  return (
    <>
      <Helmet>
        <title> Advisors/Partners | Get Will Done </title>
      </Helmet>

      <Container fullWidth style={{ maxWidth: 'unset' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          {isFromAdminDashboard && (
            <IconButton color="primary" onClick={handleBackClick} sx={{ marginRight: '8px', marginBottom: '6px' }}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography variant="h4" gutterBottom>
            Advisors/Partners
          </Typography>
          <div style={{ flexGrow: 1 }}>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          </div>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={partners.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    // const { id, type, sub_type: subType, information } = row;
                    const { id, email, phone } = row;
                    const selectedUser = selected.indexOf(id) !== -1;

                    return (
                      <>
                        <TableRow
                          sx={{ bgcolor: (theme) => (editingId === id ? 'rgba(255, 156, 65, 0.18)' : 'initial') }}
                          key={id}
                          tabIndex={-1}
                        >
                          <TableCell align="right">
                            <IconButton
                              onClick={(e) => {
                                if (editingId === id) {
                                  handleCloseMenu();
                                } else {
                                  handleOpenMenu(e, row);
                                }
                              }}
                            >
                              <Iconify icon={editingId === id ? 'uil:angle-down' : 'uil:angle-right'} />
                            </IconButton>
                          </TableCell>

                          <TableCell align="left">
                            {row.profile?.first_name && getCorrectCase(row.profile?.first_name)}{' '}
                            {row.profile?.last_name && getCorrectCase(row.profile?.last_name)}
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{convertPhoneNumber(phone)}</TableCell>
                          <TableCell align="left">{row.profile.partnerType}</TableCell>
                          <TableCell align="left">{row.partner_code}</TableCell>
                          <TableCell align="left">{row.profile.companyName}</TableCell>
                          <TableCell align="left">
                            {row.PartnerCommissions.length > 0 ? `${row.PartnerCommissions[0].commission}%` : ''}
                          </TableCell>
                          <TableCell align="left">
                            {row.PartnerCommissions.length > 0 ? `${row.PartnerCommissions[0].discount}%` : ''}
                          </TableCell>
                          <TableCell align="left">
                            {row.PartnerCommissions.length > 0
                              ? row.PartnerCommissions[0].applyType === 'xCustomers'
                                ? 'For X Individuals'
                                : 'Till X Date'
                              : ''}
                          </TableCell>
                          <TableCell align="left">
                            {row.PartnerCommissions.length > 0 ? row.PartnerCommissions[0].xCustomers : ''}
                          </TableCell>
                          <TableCell align="left">
                            {row.PartnerCommissions.length > 0
                              ? row.PartnerCommissions[0].endDate !== null
                                ? dayjs(row.PartnerCommissions[0].endDate).format('DD-MM-YYYY')
                                : ''
                              : ''}
                          </TableCell>
                          <TableCell align="left">
                            {row.partner_enroll_requested_on !== null
                              ? dayjs(row.partner_enroll_requested_on).format('DD-MM-YYYY')
                              : ''}
                          </TableCell>
                          <TableCell align="left">{row.partner_enroll_status}</TableCell>
                          <TableCell>
                            {
                              <>
                                <ButtonGroup>
                                  <Tooltip title={`Edit Partner`}>
                                    <IconButton
                                      size="small"
                                      color="primary"
                                      onClick={async (e) => {
                                        handleOpenMenu(e, row);
                                        setEdit(true);
                                      }}
                                    >
                                      <Iconify icon={'fluent:edit-32-regular'} />
                                    </IconButton>
                                  </Tooltip>
                                  {/* <Tooltip title={`Delete Partner`}>
                                                                <IconButton size="small" color="primary" onClick={async (e) => {
                                                                    Swal.fire({
                                                                        title: "Are you sure?",
                                                                        text: (`Do you want to delete the partner?`),
                                                                        icon: "warning",
                                                                        showCancelButton: true,
                                                                        allowEnterKey: false,
                                                                        allowOutsideClick: false,
                                                                        confirmButtonColor: "#3085d6",
                                                                        cancelButtonColor: "#d33",
                                                                        confirmButtonText: "Yes"
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            deletePartner(row.email);
                                                                        }
                                                                    });
                                                                }}>
                                                                    <Iconify icon={"fluent:delete-32-regular"} />
                                                                </IconButton>
                                                            </Tooltip> */}
                                </ButtonGroup>
                              </>
                            }
                          </TableCell>
                        </TableRow>
                        {editingId === id && (
                          <TableRow
                            sx={{ bgcolor: (theme) => (editingId === id ? 'rgba(255, 156, 65, 0.18)' : 'initial') }}
                          >
                            <TableCell colSpan={15}>
                              <Box sx={{ width: '100%', bgcolor: 'white', p: 2 }}>
                                {/* <Stack direction={"row"} spacing={1} alignItems="center"> */}
                                <Grid container spacing={1}>
                                  <Grid item xs={12} md={2}>
                                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                      Personal Details
                                    </Typography>
                                    <List dense={Boolean(true)}>
                                      <ListItem>
                                        <ListItemText
                                          primary="Name"
                                          secondary={`${getCorrectCase(row.profile.first_name)} ${getCorrectCase(
                                            row.profile.last_name
                                          )}`}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          primary="Date of Birth"
                                          secondary={`${
                                            row.profile?.dob ? dayjs(row.profile?.dob).format('DD-MM-YYYY') : ''
                                          }`}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          primary="Partner Code"
                                          secondary={`${row.partner_code !== null ? row.partner_code : ''}`}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          primary="PAN"
                                          secondary={row.profile.pan && `${row.profile.pan}`}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          primary="GST Identification Number"
                                          secondary={row.profile.gstin && `${row.profile.gstin}`}
                                        />
                                      </ListItem>
                                    </List>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                      Contact Details
                                    </Typography>
                                    <List dense={Boolean(true)}>
                                      <ListItem>
                                        <ListItemText primary="Email" secondary={`${row.email}`} />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          primary="Phone"
                                          secondary={row.profile.phone && `${convertPhoneNumber(row.profile.phone)}`}
                                        />
                                      </ListItem>
                                    </List>
                                  </Grid>
                                  <Grid item xs={12} md={2}>
                                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                      Company Details
                                    </Typography>
                                    <List dense={Boolean(true)}>
                                      {/* <ListItem>
                                                                            <ListItemText
                                                                                primary="KYC PAN"
                                                                            secondary={row.profile.kyc_pan && `${row.profile.kyc_pan}`}
                                                                            />
                                                                        </ListItem> */}
                                      <ListItem>
                                        <ListItemText
                                          primary="Partner Type"
                                          secondary={row.profile.partnerType && `${row.profile.partnerType}`}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          primary="Company Name"
                                          secondary={row.profile.companyName && `${row.profile.companyName}`}
                                        />
                                      </ListItem>
                                      {/* <ListItem>
                                                                            <ListItemText
                                                                                primary="KYC Document"
                                                                                secondary={<Chip label={"Download"} onClick={ () => {
                                                                                    const s3= new AWS.S3({
                                                                                        region: process.env.REACT_APP_AWS_REGION ?? 'ap-south-1',
                                                                                        accessKeyId: process.env.REACT_APP_AWS_KEY,
                                                                                        secretAccessKey: process.env.REACT_APP_AWS_SECRET,
                                                                                    });
                                                                                    // Set the parameters for the getObject request
                                                                                    const params = {
                                                                                        Bucket: process.env.NODE_ENV === 'production' ? "partner-proofs" : "gwd-testing/partner-proofs",   // Replace with your bucket name
                                                                                        Key: `${row.profile.kyc_document}`, // Replace with the path to your file
                                                                                    };

                                                                                    // Make the getObject request
                                                                                    s3.getObject(params, (err, data) => {
                                                                                        if (err) {
                                                                                            console.error('Error downloading file:', err);
                                                                                        } else {
                                                                                            // Create a Blob from the data and generate a download link
                                                                                            const blob = new Blob([data.Body], {type: 'application/octet-stream' });
                                                                                            const url = URL.createObjectURL(blob);

                                                                                            // Create a download link and trigger click to start download
                                                                                            const link = document.createElement('a');
                                                                                            link.href = url;
                                                                                            link.download = `${row.profile.first_name} ${row.profile.last_name}-${row.email}.pdf`;
                                                                                            link.click();
                                                                                            link.remove();

                                                                                            // Clean up the object URL
                                                                                            URL.revokeObjectURL(url);
                                                                                        }
                                                                                    });
                                                                                    }
                                                                                }/>}
                                                                            />
                                                                        </ListItem> */}
                                    </List>
                                  </Grid>
                                  <Grid item xs={12} md={2}>
                                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                      Banking Details
                                    </Typography>
                                    <List dense={Boolean(true)}>
                                      <ListItem>
                                        <ListItemText
                                          primary="Bank Name"
                                          secondary={row.profile.accountBankName && `${row.profile.accountBankName}`}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          primary="Account Name"
                                          secondary={row.profile.accountName && `${row.profile.accountName}`}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          primary="Account Number"
                                          secondary={row.profile.accountNumber && `${row.profile.accountNumber}`}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          primary="IFSC Code"
                                          secondary={row.profile.accountIFSC && `${row.profile.accountIFSC}`}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          primary="Branch Name"
                                          secondary={row.profile.accountBranch && `${row.profile.accountBranch}`}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          primary="City"
                                          secondary={row.profile.accountCity && `${row.profile.accountCity}`}
                                        />
                                      </ListItem>
                                    </List>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                      Address
                                    </Typography>
                                    <List dense={Boolean(true)}>
                                      <ListItem>
                                        <ListItemText
                                          primary="Current Address"
                                          secondary={
                                            row.profile.current &&
                                            `${row.profile.current?.house_no}, ${row.profile.current?.house_name}, ${
                                              row.profile.current?.street ? `${row.profile.current.street}, ` : ''
                                            } ${row.profile.current?.area}, ${row.profile.current?.city}, ${
                                              row.profile.current?.state
                                            }, ${row.profile.current?.country}, ${row.profile.current?.zip}`
                                          }
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          primary="Permanent Address"
                                          secondary={
                                            row.profile.permanent &&
                                            `${row.profile.permanent?.house_no}, ${
                                              row.profile.permanent?.house_name
                                            }, ${
                                              row.profile.current?.street ? `${row.profile.current.street}, ` : ''
                                            } ${row.profile.permanent?.area}, ${row.profile.permanent?.city}, ${
                                              row.profile.permanent?.state
                                            }, ${row.profile.permanent?.country}, ${row.profile.permanent?.zip}`
                                          }
                                        />
                                      </ListItem>
                                    </List>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                  <Grid item xs={12} md={3}>
                                    <Typography id="commission-slider-label">Commission: {commission}%</Typography>
                                    <Slider
                                      value={commission}
                                      onChange={(e) => setCommission(e.target.value)}
                                      valueLabelDisplay="auto"
                                      valueLabelFormat={(value) => `${value}%`}
                                      step={0.5}
                                      min={0}
                                      max={99.5}
                                      aria-labelledby="commission-slider-label"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <Typography id="slider-label">Customer Discount: {discount}%</Typography>
                                    <Slider
                                      value={discount}
                                      onChange={(e) => {
                                        setDiscount(e.target.value);
                                        if (e.target.value === 0) setApplyType('');
                                      }}
                                      valueLabelDisplay="auto"
                                      valueLabelFormat={(value) => `${value}%`}
                                      step={0.5}
                                      min={0}
                                      max={99.5}
                                      aria-labelledby="slider-label"
                                    />
                                  </Grid>
                                  {discount > 0 && (
                                    <Grid item xs={12} md={3}>
                                      <FormControl fullWidth>
                                        <InputLabel id="coupon-type-select-label">Discount</InputLabel>
                                        <Select
                                          fullWidth
                                          labelId="coupon-type-select-label"
                                          label="Discount"
                                          value={applyType}
                                          onChange={(e) => {
                                            setApplyType(e.target.value);
                                            setXCustomers('');
                                            setEndDate('');
                                          }}
                                        >
                                          <MenuItem value="xCustomers">For X Individuals</MenuItem>
                                          <MenuItem value="xDate">Till X Date</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  )}
                                  {applyType === 'xCustomers' && (
                                    <Grid item xs={12} md={3}>
                                      <TextField
                                        fullWidth
                                        type="number"
                                        label="Discount for Individuals"
                                        value={xCustomers}
                                        inputProps={{
                                          step: 1, // To allow floating-point numbers
                                          min: 1, // Minimum value
                                          max: 1000, // Maximum value
                                        }}
                                        onChange={(e) => {
                                          let { value } = e.target;
                                          const integerRegex = /^[0-9]+$/;
                                          // const regex = /^\d*\.?\d{0,2}0*$/;
                                          console.log('regextest', value, integerRegex.test(value));
                                          if (integerRegex.test(value)) {
                                            if (Number.isNaN(value)) {
                                              value = 1;
                                            } else if (value !== '' && value < 1) {
                                              value = 1;
                                            } else if (value > 1000) {
                                              value = 1000;
                                            }
                                            setXCustomers(value);
                                          }
                                        }}
                                      />
                                    </Grid>
                                  )}
                                  {applyType === 'xDate' && (
                                    <Grid item xs={12} md={3}>
                                      <FormControl required error={Boolean(true)} fullWidth variant="standard">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <DatePicker
                                            label="End Date (Expiry Date)"
                                            labelId={`input-end-date-label`}
                                            id={`input-end-date`}
                                            value={endDate}
                                            // onError={(newError) => newError}
                                            error={Boolean(true)}
                                            inputFormat={'dd-MM-yyyy'}
                                            isValid={(date) => {
                                              console.log('isValid', date);
                                            }}
                                            minDate={dayjs().startOf('day')}
                                            // minDateMessage="Past dates are not allowed"
                                            onChange={(date) => {
                                              console.log(
                                                'dayjs',
                                                date,
                                                dayjs(date).startOf('day'),
                                                dayjs(date).startOf('day').isSameOrAfter(dayjs().startOf('day')),
                                                dayjs().startOf('day')
                                              );
                                              setIsFormatValid(true);
                                              setEndDate(date);
                                              // const regex = /^\d{2}-\d{2}-\d{4}$/;
                                              const regex = /^\d{2}-\d{2}-(20\d{2}|21\d{2})$/;
                                              console.log(
                                                'regexvalid',
                                                dayjs(date).format('DD-MM-YYYY'),
                                                regex.test(dayjs(date).format('DD-MM-YYYY'))
                                              );
                                              if (regex.test(dayjs(date).format('DD-MM-YYYY'))) {
                                                if (dayjs(date).startOf('day').isSameOrAfter(dayjs().startOf('day'))) {
                                                  console.log('if');
                                                  setEndDteError(null);
                                                } else {
                                                  console.log('else');
                                                  setEndDteError('End date must be today or future date');
                                                }
                                              } else {
                                                console.log('else');
                                                setEndDteError('Please enter a valid date (DD-MM-YYYY) with hyphen');
                                              }
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                error={endDateError ?? false}
                                                helperText={endDateError ?? ''}
                                                InputProps={{ readOnly: true }}
                                                fullWidth
                                                {...params}
                                                placeholder={'DD-MM-YYYY'}
                                              />
                                            )}
                                          />
                                          {/* {endDateError && (
                                                                                    <FormHelperText color='error'>{endDateError}</FormHelperText>
                                                                                )} */}
                                        </LocalizationProvider>
                                      </FormControl>
                                    </Grid>
                                  )}
                                  <Grid item xs={12} md={12}>
                                    <Grid container spacing={1}>
                                      <Grid item xs={12} md={3}>
                                        {/* <FormControl component="fieldset">
                                                                                <FormLabel component="legend">Approve Status</FormLabel>
                                                                                <Stack direction="row" spacing={1} alignItems="center">
                                                                                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Reject</Typography>
                                                                                <Switch id="button-status-toggle" checked={approveStatus} size="string" onChange={(e) => { setApproveStatus(e.target.checked); console.log('approve status', e.target.checked)}} />
                                                                                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Approve</Typography>
                                                                                </Stack>
                                                                            </FormControl> */}
                                        <FormControl fullWidth>
                                          <InputLabel id="enroll-status-select-label">Enroll Status</InputLabel>
                                          <Select
                                            fullWidth
                                            labelId="enroll-status-select-label"
                                            label="Enroll Status"
                                            value={enrollStatus}
                                            onChange={(e) => {
                                              setEnrollStatus(e.target.value);
                                            }}
                                          >
                                            <MenuItem value={null}>None</MenuItem>
                                            <MenuItem value="REQUESTED">REQUESTED</MenuItem>
                                            <MenuItem value="APPROVED">APPROVED</MenuItem>
                                            <MenuItem value="REJECTED">REJECTED</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      {!row.profile.gstin && (
                                        <Grid item xs={12} md={3}>
                                          <FormControl fullWidth>
                                            {/* <InputLabel id="enroll-status-select-label">RCM (Advocates)</InputLabel> */}

                                            <FormControlLabel
                                              label="RCM (Advocates)"
                                              control={
                                                <Checkbox
                                                  checked={rcmAdvocate}
                                                  onChange={(e) => {
                                                    setRCMAdvocate(e.target.checked);
                                                  }}
                                                  inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                              }
                                            />
                                          </FormControl>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Stack direction="row" justifyContent={'space-between'} spacing={1}>
                                  <Button
                                    style={{ marginTop: '8px' }}
                                    onClick={() => {
                                      handleCloseMenu();
                                    }}
                                    variant="text"
                                  >
                                    Cancel
                                  </Button>
                                  {edit && (
                                    <Button
                                      disabled={
                                        (discount === 0 ? false : applyType === '') ||
                                        (discount === 0
                                          ? false
                                          : applyType === 'xCustomers'
                                          ? xCustomers === ''
                                            ? Boolean(true)
                                            : Boolean(false)
                                          : endDate === ''
                                          ? !dayjs(endDate).startOf('day').isSameOrAfter(dayjs().startOf('day'))
                                          : !dayjs(endDate).startOf('day').isSameOrAfter(dayjs().startOf('day'))) ||
                                        errors.length > 0
                                      }
                                      style={{ marginTop: '8px' }}
                                      onClick={() => {
                                        editPartner();
                                      }}
                                      variant="contained"
                                    >
                                      Save
                                    </Button>
                                  )}
                                </Stack>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={14} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={partners.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
