import { Box, Grid, Typography, useTheme } from '@mui/material';
import DateInput from 'components/DateInput';
import InputField from 'components/DesignSystem/InputField';
import RadioField from 'components/DesignSystem/RadioGroup';
import useSwalAlert from 'components/DesignSystem/SwalAlert/SwalAlert';
import FileUpload from 'components/fileUpload';
import { useFormik } from 'formik';
import { useState } from 'react';
import { uploadWillApi } from 'services/profileService';
import formValidation from 'utils/helper/formValidations';
import * as Yup from 'yup';
import { ContainedButton1, TextedButton1 } from '../../../components/DesignSystem/Button';

const ExistingWill = ({ data }) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false); // Loading state
    const showSwalAlert = useSwalAlert();
    const [isUploaded, setIsUploaded] = useState(false);
    const [isRegistered, setIsRegistered] = useState(null);

    const initialValues = {
        signing_date: null,
        registrar_place: '',
        registrar_number: '',
        serial_number: '',
        file: null
    };

    const validationSchema = Yup.object().shape({
        signing_date: formValidation.dateValidation.required('required field'),
        // registrar_place: formValidation.registrarPlaceValidation,
        // registrar_number: formValidation.numberValidation.required('required field'),
        // serial_number: formValidation.numberValidation.required('required field'),
        file: formValidation.fileValidation
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true); // Set loading to true
                // Convert file to base64
                const base64File = await convertFileToBase64(values.file);

                // Prepare payload
                const WillData = {
                    file: base64File,
                    signed_at: values.signing_date,
                    registered_with: values.registrar_place,
                    registered_office_number: values.registrar_number,
                    serial_number: values.serial_number
                };

                // Send the data to the API
                await uploadWillApi({ will: WillData });

                // Show SweetAlert2 notification and wait for it to close
                await showSwalAlert({
                    title: "Will uploaded successfully",
                    icon: "success",
                    timer: 1500
                });
                setIsUploaded(true);
                // Reset form and navigate to another page
                formik.resetForm();

            } catch (err) {
                // Show SweetAlert2 notification and wait for it to close
                await showSwalAlert({
                    title: "Failde to upload Will",
                    icon: "error",
                    timer: 1500
                });
                console.log("Failed to upload file")
            } finally {
                setLoading(false); // Reset loading state
            }
        }
    });

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const handleSave = () => {
        formik.handleSubmit();
    }

    const handleCancel = () => {
        formik.resetForm();
    }

    const radioOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ];

    const handleRadioChange = (event) => {
        const { value } = event.target;
        setIsRegistered(value === 'true'); // Update local state based on radio selection
    };

    return (
        <>
            <Box
                sx={{
                    p: 2, // Padding inside the Box
                    backgroundColor: '#F5F5F5',
                    borderRadius: '15px',
                    marginTop: '96px',
                    marginRight: '94px'
                }}
            >
                <Box mt='8px'>
                    <Typography variant="heading2" color={theme.palette.secondary.main} gutterBottom >
                        Existing Will
                    </Typography>
                </Box>
                <form onSubmit={formik.handleSubmit}>
                    <Box
                        container
                        sx={{
                            display: 'flex',
                            mt: '21px',
                            mr: '58px',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Grid container spacing={3.5}>
                            <Grid item xs={12} md={7.8} sm={12}>
                                <DateInput
                                    name="signing_date"
                                    value={formik.values.signing_date}
                                    label=" Signing Date"
                                    onChange={value => formik.setFieldValue('signing_date', value)}
                                    helperText={formik.touched.signing_date && formik.errors.signing_date}
                                    error={formik.touched.signing_date && Boolean(formik.errors.signing_date)}
                                    onBlur={() => formik.setFieldTouched('signing_date', true)}
                                    sx={{
                                        height: '2.5rem',
                                        backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                    }}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <RadioField
                                    label="Is the Will registered?"
                                    value={isRegistered}
                                    onChange={handleRadioChange}
                                    options={radioOptions}
                                // error={isRegistered === null}
                                // helperText={"required field"}
                                />
                            </Grid>

                            {isRegistered && (<>
                                <Grid item xs={12}>
                                    <InputField
                                        fullWidth
                                        name="registrar_place"
                                        placeholder="Sub-Registrar's Office Place*"
                                        value={formik.values.registrar_place}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.registrar_place && Boolean(formik.errors.registrar_place)}
                                        helperText={formik.touched.registrar_place && formik.errors.registrar_place}
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                height: '2.5rem'
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} >
                                    <InputField
                                        fullWidth
                                        name="registrar_number"
                                        placeholder="Sub-Registrar's Office Number*"
                                        variant="outlined"
                                        value={formik.values.registrar_number}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.registrar_number && Boolean(formik.errors.registrar_number)}
                                        helperText={formik.touched.registrar_number && formik.errors.registrar_number}
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                height: '2.5rem'
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputField
                                        fullWidth
                                        name="serial_number"
                                        placeholder="Serial Number*"
                                        variant="outlined"
                                        value={formik.values.serial_number}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.serial_number && Boolean(formik.errors.serial_number)}
                                        helperText={formik.touched.serial_number && formik.errors.serial_number}
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                height: '2.5rem'
                                            },
                                        }}
                                    />
                                </Grid>
                            </>)}
                            <Grid item xs={12}>
                                <FileUpload
                                    name="file"
                                    label="Upload File"
                                    accept="application/pdf"
                                    maxFileSize={10485760} // 10MB
                                    value={formik.values.file}
                                    onChange={(file) => formik.setFieldValue("file", file)}
                                    error={formik.errors.file && formik.touched.file ? formik.errors.file : null}
                                    isUploaded={isUploaded}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Box>
            <Box sx={{
                display: 'flex', justifyContent: 'flex-end', gap: '10px', mt: '16px', mr: '94px'
            }}>
                <TextedButton1
                    sx={{ height: '40px', width: '109px' }}
                    disabled={loading} // Disable if loading
                    onClick={handleCancel}
                >
                    <Typography variant='bodyText3'>
                        Cancel
                    </Typography>
                </TextedButton1>

                <ContainedButton1
                    onClick={handleSave}
                    disabled={!(formik.isValid && formik.dirty) || loading || isRegistered === null} // Disable if loading
                    sx={{ height: '40px', width: '109px' }}
                >
                    <Typography variant='bodyText3'>
                        Save
                    </Typography>
                </ContainedButton1>
            </Box>
        </>
    );
};

export default ExistingWill;
