import { useTheme } from '@emotion/react';
import { Box, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import BackButtonWithDivider from 'components/DesignSystem/BackButtonWithDivider';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import Logo from 'components/logo';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

export default function JourneyPage() {
  const theme = useTheme();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNext = () => {
    navigate('/personalDetails'); // Navigate to the JourneyPage
  };

  return (
    <>
      <Helmet>
        <title> Welcome | Get Will Done </title>
      </Helmet>
      <Box
        sx={{
          position: 'absolute',
          top: '2.8rem',
          left: '2rem',
          width: '16rem',
          height: '8rem',
          '@media (max-width: 600px)': {
            top: '1rem',
          },
          '@media (max-width: 1270px)': {
            top: '1rem',
            left: '5rem',
          },
        }}
      >
        <Logo />
      </Box>
      <Box
        sx={{
          maxWidth: 800,
          height: '100%',
          margin: 'auto',
          // marginLeft:14,
          padding: 8,
          paddingTop: 0,
          paddingLeft: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Box sx={{ marginTop: '4.875rem', marginLeft: '6px' }}>
          <Box sx={{ mb: '20px', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', width: '100%' }}>
            <BackButtonWithDivider />
            <Typography variant="title1" gutterBottom>
              Your path to Will creation
            </Typography>
          </Box>

          <Stepper
            orientation="vertical"
            sx={{
              '& .MuiStepConnector-root': {
                marginLeft: '25px',
              },
              '& .MuiStepConnector-line': {
                borderColor: theme.palette.primary.dark,
              },
            }}
          >
            {steps.map((step, index) => (
              <Step key={index} active>
                <StepLabel
                  icon={<img src={step.icon} alt={`Step ${index + 1}`} style={{ width: '50px', height: '50px' }} />}
                >
                  <Typography variant="bodyText3" fontWeight={'400'} sx={{ mb: '63px' }}>
                    {step.label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ display: 'flex', justifyContent: 'left', md: 4 }}>
            <ContainedButton1
              sx={{
                mt: '154px',
                minWidth: '125px',
              }}
              onClick={handleNext}
            >
              <Typography variant="bodyText3">Next</Typography>
            </ContainedButton1>
          </Box>
        </Box>
      </Box>
    </>
  );
}

const steps = [
  {
    label: 'First, create your profile with important personal details.',
    icon: '/assets/icons/Profile.svg',
  },
  {
    label: 'Next, add the people you love and trust to be an integral part of your Will.',
    icon: '/assets/icons/Beneficiary.svg',
  },
  {
    label: 'After that, add all your assets that you have worked hard for and want to pass on.',
    icon: '/assets/icons/Assets.svg',
  },
  {
    label: 'Lastly, distribute the assets among your beneficiaries, the way you want.',
    icon: '/assets/icons/Distribution.svg',
  },
  {
    label: 'You can Preview your Will at any time, and Generate it when you are ready.',
    icon: '/assets/icons/Alert_Copy.svg',
  },
];
