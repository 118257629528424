import { ContextProvider } from 'App';
import FullScreenLoader from 'components/DesignSystem/FullScreenLoader';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

const ProtectedRoutes = () => {
    const { loggedIn, fetchUser, setLoggedIn } = useContext(ContextProvider);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    const stableFetchUser = useCallback(async () => {
        try {
            const response = await fetchUser();
            const { user } = response.data;

            if (user.role === 'admin') {
                navigate('/admin/analyticaldashboard', { replace: true });
            } else if (user.role === 'testator' || user.role === 'partner') {
                // Existing check for testator role
                if (user.profile_saved && ['/welcome', '/journey', '/personalDetails', '/addressDetails', '/existingDetails', '/profileCompletion'].some((path) => location.pathname.includes(path))) {
                    navigate('/landingPage');
                }
            }

            setLoggedIn(true);
            setLoading(false);
        } catch (err) {
            console.error('Failed to fetch user:', err);
            setLoggedIn(false);
            setLoading(false);
            if (location.pathname !== '/login') {
                navigate('/login', { replace: true });
            }
        }
    }, [fetchUser, setLoggedIn, location, navigate]);

    useEffect(() => {
        if (!loggedIn) {
            stableFetchUser();
        } else {
            setLoading(false);
        }
    }, [loggedIn, stableFetchUser]);

    if (loading) {
        return <FullScreenLoader />;
    }

    return loggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;