import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Logo from 'components/logo/Logo';
import { ProfileIcon } from 'views/dashboard/PageContainer';


// Styled components
const StyledHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    gap: theme.spacing(3), // Adjust spacing based on your design
    width: '100%',
    boxSizing: 'border-box',
    // padding: theme.spacing(0, 3, 0, 0),
    maxWidth: '100%',
}));

const StyledLogoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
}));

const StyledDivider = styled(Box)(({ theme }) => ({
    height: 1,
    width: '100%',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
}));

const Header = () => {
    return (
        <StyledHeader>
            <StyledLogoContainer>
                <Box sx={{ width: '190px', height: '95px', marginTop: '27px' }} >
                    <Logo disabledLink />
                </Box>
                <ProfileIcon />
            </StyledLogoContainer>
            <StyledDivider />
        </StyledHeader>
    );
};

export default Header;
