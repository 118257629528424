import { Box, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import AlertDialog from 'components/DesignSystem/AlertDialog';
import BottomFloatingAction from 'components/DesignSystem/BottomFloatingAction';
import CreateDistributionModal from 'components/featureComponent/distribution/CreateDistributionModal';
import { noop } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { deleteScenario, deleteWill, fetchWills, updateMappings } from 'services/distributionService';
import Swal from 'sweetalert2';
import ScenarioTabs from './ScenarioTabs';

const SecondaryDistribution = ({
    filteredUsers = {},
    normalizedWills = [],
    mappings = {},
    generics = {},
    setMappings = noop,
    mappingBalance = 0,
    petMappingBalance = [],
    setGenerics = noop,
    loading,
    setLoading,
}) => {
    const [selectDistributionModalOpen, setSelectDistributionModalOpen] = useState(false);
    const [distributionError, setDistributionError] = useState(false);
    const [selectedScenarioTab, setSelectedScenarioTab] = useState(0);
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
    const [resetInfo, setResetInfo] = useState({});
    const [previousMappings, setPreviousMappings] = useState({});
    const [step, setStep] = useState(1);
    const theme = useTheme();

    const { user } = useContext(ContextProvider);

    const beneficiaryNotAdded = generics?.beneficiary?.length === 0;

    useEffect(() => {
        const scenarioMappings = normalizedWills.filter(
            (will) => will.ScenarioId === generics?.scenarios?.[selectedScenarioTab]?.id
        );

        setMappings(scenarioMappings);
        setPreviousMappings(structuredClone(scenarioMappings)); // To create deep copy

        if (
            generics?.scenarios?.length > 0 &&
            scenarioMappings.length === 0 &&
            !loading &&
            generics?.assets?.length > 0
        ) {
            setSelectDistributionModalOpen(true);
            setStep(2);
        } else {
            setSelectDistributionModalOpen(false);
            setStep(1);
        }
    }, [normalizedWills, generics?.scenarios, selectedScenarioTab, loading]);

    useEffect(() => {
        let error = false;
        filteredUsers.forEach((row) => {
            const { id, information } = row;
            const will = mappings?.find(({ PropertyId }) => {
                return PropertyId === id;
            });
            let value = 100;
            let total = 0;
            if (will?.distribution_type === 'value') {
                value =
                    information?.value ??
                    information?.amount ??
                    information?.insured_amount ??
                    information?.investment_amount ??
                    information?.deposited_amount;
            } else if (will?.distribution_type === 'quantity') {
                value = information?.quantity ?? information?.qty ?? information?.number;
            }
            will?.mapping?.forEach((mapping) => {
                total += +(mapping.share ?? 0);
            });
            // if (Math.abs(Number((value - total).toFixed(2))) <= 0.01) {
            //     total = value;
            // }
            if (total > value) {
                error = true;
            }
        });
        setDistributionError(error);
    }, [filteredUsers, mappings]);

    const selectedScenario = useMemo(() => {
        return generics?.scenarios?.[selectedScenarioTab];
    }, [selectedScenarioTab, generics?.scenarios]);

    const handleAddClick = () => {
        setStep(1);
        setSelectDistributionModalOpen(true);
    };

    const handleOnClose = () => {
        setSelectDistributionModalOpen(false);
    };

    const handleAlertDialogClose = () => {
        setIsAlertDialogOpen(false);
    };

    const handleOnResetClick = (selectedScenarioId, assetId) => {
        setResetInfo({ scenarioId: selectedScenarioId, assetId });
        setIsAlertDialogOpen(true);
    };

    const fetchWillsData = async (switchToNew = false) => {
        try {
            const response = await fetchWills();
            setGenerics((generics) => ({
                ...generics,
                wills: response.data?.wills ?? [],
                scenarios: response.data?.scenarios ?? [],
            }));
            if (switchToNew) {
                setSelectedScenarioTab(response.data?.scenarios?.length - 1 ?? 0);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const handleResetConfirmation = async () => {
        try {
            await deleteWill(resetInfo.scenarioId, resetInfo.assetId);
            await fetchWillsData();
        } catch (err) {
            console.log(err);
        } finally {
            setIsAlertDialogOpen(false);
        }
    };

    const updateMappingsData = async () => {
        setLoading(true);
        try {
            const payload = {
                mappings: mappings.filter((map) => map.id !== -99) ?? [],
            };
            const response = await updateMappings(selectedScenario?.id ?? 0, payload);
            Swal.fire({
                position: 'top-center',
                icon: 'success',
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Mapping updated successfully`,
                showConfirmButton: false,
                timer: 3000,
            });
            await fetchWillsData();
        } catch (err) {
            // toastr.error("Failed to update asset")
            Swal.fire({
                position: 'top-center',
                icon: 'error',
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Failed to update asset`,
                showConfirmButton: false,
                timer: 3000,
            });
            setLoading(false);
        }
    };

    const resetMappingData = async (newMappings) => {
        try {
            const payload = {
                mappings: newMappings.filter((map) => map.id !== -99) ?? [],
            };
            const response = await updateMappings(selectedScenario?.id ?? 0, payload);
            Swal.fire({
                position: 'top-center',
                icon: 'success',
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Mapping reset successfully`,
                showConfirmButton: false,
                timer: 3000,
            });
            await fetchWillsData();
        } catch (err) {
            // toastr.error("Failed to update asset")
            Swal.fire({
                position: 'top-center',
                icon: 'error',
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Failed to reset asset`,
                showConfirmButton: false,
                timer: 3000,
            });
        }
    };

    const deleteScenarioData = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to delete the Scenario?`,
            icon: 'warning',
            showCancelButton: true,
            allowEnterKey: false,
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    const response = deleteScenario(selectedScenario.id);
                    Swal.fire({
                        position: 'top-center',
                        icon: 'success',
                        color: '#fff',
                        background: '#00838F',
                        toast: true,
                        title: `Distribution deleted successfully`,
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    fetchWillsData();
                    setSelectedScenarioTab(0);
                } catch (err) {
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        color: '#fff',
                        background: '#00838F',
                        toast: true,
                        title: `Failed to delete scenario`,
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }
            }
        });
    };

    const handleSaveDistribution = () => {
        if (petMappingBalance.length > 0 && petMappingBalance.some((value) => value === false)) {
            Swal.fire({
                title: 'Pet/Vehicle assets not distributed properly',
                text: 'Distribution of pet/vehicle assets is limited to a single beneficiary, with entire allocation (100%).',
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                allowEnterKey: false,
                allowOutsideClick: false,
                // confirmButtonColor: "#3085d6",
                cancelButtonColor: '#d33',
                cancelButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    // updateMappingsData();
                }
            });
        } else if (mappingBalance > 0) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Some assets are not distributed completely. Do you wish to continue?',
                icon: 'warning',
                showCancelButton: true,
                allowEnterKey: false,
                allowOutsideClick: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, update distribution',
            }).then((result) => {
                if (result.isConfirmed) {
                    updateMappingsData();
                }
            });
        } else {
            updateMappingsData();
        }
    };

    const handleResetScenario = async () => {
        const newMappings = filteredUsers?.map((as) => {
            const distributionType = as.sub_type === 'livestock' ? 'quantity' : 'percentage';
            return {
                id: -1,
                PropertyId: as.id,
                distribution_type: distributionType,
                mapping: [],
            };
        });
        setMappings(newMappings);
        await resetMappingData(newMappings);
    };

    const handleResetDistribution = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to Reset the Scenario?`,
            icon: 'warning',
            showCancelButton: true,
            allowEnterKey: false,
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                handleResetScenario();
            }
        });
    };

    const handleCancelDistribution = () => {
        setMappings(structuredClone(previousMappings));
    };

    return (
        <>
            <Box paddingRight="75px" paddingBottom="84px">
                <ScenarioTabs
                    handleAddClick={handleAddClick}
                    generics={generics}
                    filteredUsers={filteredUsers}
                    mappings={mappings}
                    setMappings={setMappings}
                    normalizedWills={normalizedWills}
                    setDistributionError={setDistributionError}
                    selectedScenarioTab={selectedScenarioTab}
                    setSelectedScenarioTab={setSelectedScenarioTab}
                    selectedScenario={selectedScenario}
                    handleOnResetClick={handleOnResetClick}
                    deleteScenario={deleteScenarioData}
                />
                {generics?.scenarios?.length > 0 && ['testator', 'partner']?.includes(user?.role) && (
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 50,
                            left: 146,
                            right: 146,
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <BottomFloatingAction
                            message="Would you like to save or reset the distribution?"
                            firstButtonText="Cancel"
                            onFirstButtonClick={handleCancelDistribution}
                            firstButtonDisabled={beneficiaryNotAdded}
                            firstButtonSx={{
                                minWidth: '75px',
                                padding: '6px 0',
                                border: `1px solid ${theme.palette.primary.main}40`,
                                // height: '30px',
                            }}
                            secondButtonText="Reset"
                            onSecondButtonClick={handleResetDistribution}
                            secondButtonDisabled={beneficiaryNotAdded}
                            secondButtonSx={{ minWidth: '75px', padding: '6px 0', }}
                            thirdButtonText="Save Distribution"
                            onThirdButtonClick={handleSaveDistribution}
                            thirdButtonDisabled={beneficiaryNotAdded || distributionError}
                            thirdButtonSx={{ padding: '6px 12px', }}
                        />
                    </Box>
                )}
            </Box>
            <CreateDistributionModal
                open={selectDistributionModalOpen}
                onClose={handleOnClose}
                generics={generics}
                fetchWillsData={fetchWillsData}
                selectedScenario={selectedScenario}
                step={step}
                setStep={setStep}
                filteredUsers={filteredUsers}
                setMappings={setMappings}
            />
            <AlertDialog
                open={isAlertDialogOpen}
                onClose={handleAlertDialogClose}
                title={`Are you sure you want to reset this asset?`}
                primaryButtonLabel="Keep"
                secondaryButtonLabel="Reset"
                onPrimaryButtonClick={handleAlertDialogClose} // Handle 'Keep' action
                onSecondaryButtonClick={handleResetConfirmation} // Handle 'Delete' action
                selectedScenario={selectedScenario}
            />
        </>
    );
};

export default SecondaryDistribution;
