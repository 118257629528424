import {
  Box
} from '@mui/material';
import { useState } from 'react';
import PageContainer from 'views/dashboard/PageContainer';
import Sidebar from '../../components/featureComponent/profileSection/Sidebar';
import AddressDetails from './AddressDetails/AddressDetails';
import ExistingWill from './ExistingWill/ExistingWill';
import PersonalDetails from './PersonalDetails/PersonalDetails';
import PurchaseHistory from './PurchaseHistory/PurchaseHistory';
import VersionHistory from './VersionHistory/VersionHistory';
// Helper function for TabPanel
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: '100%'}}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const ProfilePage = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const purchaseData = [
    { orderId: '#35081', service: 'Call with Legal Expert', date: '22/08/2024', invoice: 'View' },
    { orderId: '#35081', service: 'Call with Legal Expert', date: '18/10/2023', invoice: 'View' },
    { orderId: '#35081', service: 'Call with Legal Expert', date: '12/10/2022', invoice: 'View' },
    { orderId: '#35081', service: 'Call with Legal Expert', date: '10/10/2021', invoice: 'View' },
  ];

  const versionData = [
    { version: '04', date: '22/08/2024', status: 'Active', invoice: 'View' },
    { version: '03', date: '18/10/2023', status: 'Closed', invoice: 'View' },
    { version: '02', date: '12/10/2022', status: 'Closed', invoice: 'View' },
    { version: '01', date: '10/10/2021', status: 'Closed', invoice: 'View' },
  ];

  return (
    <PageContainer showHelpIcon={false}>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Sidebar value={tabValue} handleTabChange={handleTabChange} />
        {/* Tab Panels */}
        <TabPanel value={tabValue} index={0}>
          <PersonalDetails />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <AddressDetails />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ExistingWill />
        </TabPanel>
        <TabPanel value={tabValue} index={3} >
          <PurchaseHistory data={purchaseData} /> {/* Render PurchaseHistory */}
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <VersionHistory data={versionData} /> {/* Render PurchaseHistory */}
        </TabPanel>
      </Box>
    </PageContainer>
  );
};

export default ProfilePage;
