import axios from "axios";
import GDPR from "layout/Gdpr";
import NavigationScroll from "layout/NavigationScroll";
import { createContext, useState } from "react";
import ReactGA from 'react-ga4';
import usePreventScreenshot from "./hooks/usePreventScreenshot";
import Routes from "./routes";
import "./styles.css";
import ThemeProvider from "./theme";

const GA4_TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID ?? "G-HTKPF0G88W";
ReactGA.initialize(GA4_TRACKING_ID);

export const ContextProvider = createContext({});

export default function App() {
    const [user, setUser] = useState({});
    const [loggedIn, setLoggedIn] = useState(false);

    const fetchUser = async () => {
        const response = await axios.get('/auth/user');
        setUser(response.data.user);
        return response;
    };

    usePreventScreenshot();

    return (
        <ThemeProvider>
            {/* <LoadingContext.Provider value={{ isLoading, showHelp, setShowHelp, editProfile }}> */}
            <ContextProvider.Provider value={{ user, fetchUser, loggedIn, setLoggedIn, setUser }}>
                <NavigationScroll>
                    {/* <Router /> */}
                    <Routes />
                    {!user.consentAccepted && loggedIn && (
                        <GDPR />

                    )}
                </NavigationScroll>
            </ContextProvider.Provider>
        </ThemeProvider>
    );
}
