import { Box, Chip, Typography, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import AlertDialog from 'components/DesignSystem/AlertDialog';
import BottomFloatingAction from 'components/DesignSystem/BottomFloatingAction';
import SelectDistributionModal from 'components/featureComponent/distribution/SelectDistributionModal';
import { noop } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { deleteScenario, deleteWill, fetchWills, updateMappings } from 'services/distributionService';
import Swal from 'sweetalert2';
import DistributionCard from './DistributionCard';

const PrimaryDistribution = ({
    filteredUsers = {},
    normalizedWills = [],
    mappings = {},
    generics = {},
    setMappings = noop,
    mappingBalance = null,
    petMappingBalance = [],
    fetchWillsData = noop,
    loading = false,
    setLoading,

}) => {
    const theme = useTheme();
    const [selectDistributionModalOpen, setSelectDistributionModalOpen] = useState(false);
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
    const [confirmDistributionAlertOpen, setConfirmDistributionAlertOpen] = useState(false);
    const [distributionError, setDistributionError] = useState(false);
    const [resetInfo, setResetInfo] = useState({});
    const [previousMappings, setPreviousMappings] = useState({});
    const { user } = useContext(ContextProvider);

    const primaryMappings = normalizedWills?.filter((will) => will.ScenarioId === 0);

    const beneficiaryNotAdded = generics?.beneficiary?.length === 0;

    useEffect(() => {
        const scenarioMappings = normalizedWills.filter((will) => will.ScenarioId === 0);
        setMappings(scenarioMappings);
        setPreviousMappings(structuredClone(scenarioMappings)); // To create deep copy
    }, [normalizedWills, generics?.scenarios, loading]);

    const selectedScenario = useMemo(() => {
        return { id: 0, deaths: [] };
    }, [generics?.scenarios]);

    // useEffect(() => {
    //     console.log("in useeffecttt mappings", mappings);
    //     console.log("in useeffecttt generics.assets", generics.assets);

    //     if (mappings.length === 0 && generics?.assets?.length > 0) {
    //         handleSelectDistributionDialogOpen();
    //     } else {
    //         handleOnClose();
    //     }
    // }, [generics?.assets, mappings]);

    useEffect(() => {
        // Ensure generics.assets is loaded before running the logic
        if (generics?.assets && mappings.length === 0 && !loading) {
            handleSelectDistributionDialogOpen();
        } else if (mappings.length > 0) {
            handleOnClose();
        }
    }, [generics?.assets, mappings]);

    const handleSelectDistributionDialogOpen = () => {
        setSelectDistributionModalOpen(true);
    };

    const handleOnClose = () => {
        setSelectDistributionModalOpen(false);
    };

    const handleAlertDialogClose = () => {
        setIsAlertDialogOpen(false);
    };

    const handleConfirmAlertDialogClose = () => {
        setConfirmDistributionAlertOpen(false);
    };

    const handleConfirmAlertDialogOpen = () => {
        setConfirmDistributionAlertOpen(true);
    };

    const handleConfirmAlertConfirmation = () => {
        handleConfirmAlertDialogClose();
        handleSelectDistributionDialogOpen();
    };

    const handleOnResetClick = (selectedScenarioId, assetId) => {
        setResetInfo({ scenarioId: selectedScenarioId, assetId });
        setIsAlertDialogOpen(true);
    };

    const handleResetConfirmation = async () => {
        try {
            setLoading(true);
            await deleteWill(resetInfo.scenarioId, resetInfo.assetId);
            await fetchWillsData();
        } catch (err) {
            console.log(err);
        } finally {
            setIsAlertDialogOpen(false);
            setLoading(false);
        }
    };

    const updateMappingsData = useCallback(async () => {
        // setLoading(true);
        try {
            const payload = {
                mappings: mappings.filter((map) => map.id !== -99) ?? [],
            };
            const response = await updateMappings(0, payload);
            Swal.fire({
                position: 'top-center',
                icon: 'success',
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Mapping updated successfully`,
                showConfirmButton: false,
                timer: 3000,
            });
            await fetchWillsData();
            setLoading(false);
        } catch (err) {
            Swal.fire({
                position: 'top-center',
                icon: 'error',
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Failed to update asset`,
                showConfirmButton: false,
                timer: 3000,
            });
            // setLoading(false)
        }
    }, [mappings]);

    useEffect(() => {
        let error = false;
        filteredUsers.forEach((row) => {
            const { id, information } = row;
            const will = mappings?.find(({ PropertyId }) => {
                return PropertyId === id;
            });
            let value = 100;
            let total = 0;
            if (will?.distribution_type === 'value') {
                value =
                    information?.value ??
                    information?.amount ??
                    information?.insured_amount ??
                    information?.investment_amount ??
                    information?.deposited_amount;
            } else if (will?.distribution_type === 'quantity') {
                value = information?.quantity ?? information?.qty ?? information?.number;
            }
            will?.mapping?.forEach((mapping) => {
                total += +(mapping.share ?? 0);
            });
            // if (Math.abs(Number((value - total).toFixed(2))) <= 0.01) {
            //     total = value;
            // }
            if (total > value) {
                error = true;
            }
        });
        setDistributionError(error);
    }, [filteredUsers, mappings]);

    const handleSaveDistribution = () => {
        if (petMappingBalance.length > 0 && petMappingBalance.some((value) => value === false)) {
            Swal.fire({
                title: 'Pet/Vehicle assets not distributed properly',
                text: 'Distribution of pet/vehicle assets is limited to a single beneficiary, with entire allocation (100%).',
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                allowEnterKey: false,
                allowOutsideClick: false,
                // confirmButtonColor: "#3085d6",
                cancelButtonColor: '#d33',
                cancelButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    // updateMappingsData();
                }
            });
        } else if (mappingBalance > 0) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Some assets are not distributed completely. Do you wish to continue?',
                icon: 'warning',
                showCancelButton: true,
                allowEnterKey: false,
                allowOutsideClick: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, update distribution',
            }).then((result) => {
                if (result.isConfirmed) {
                    updateMappingsData();
                }
            });
        } else {
            updateMappingsData();
        }
    };

    const handleResetDistribution = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to reset the Primary Distribution?`,
            icon: 'warning',
            showCancelButton: true,
            allowEnterKey: false,
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteScenario(0);
                fetchWillsData();
            }
        });
    };

    const handleCancelDistribution = () => {
        setMappings(structuredClone(previousMappings)); // To create deep copy
    };

    return (
        <Box paddingRight="75px" paddingBottom="100px" sx={{ position: 'relative' }}>
            <Box>
                <Box textAlign="end" paddingTop="15px" paddingBottom="17px">
                    <Chip
                        clickable
                        sx={{ maxHeight: '24px' }}
                        onClick={handleConfirmAlertDialogOpen}
                        label={
                            <Typography color={theme.palette.primary.main} variant="smallText" sx={{ fontSize: '13px !important' }}>
                                Change Distribution Method
                            </Typography>
                        }
                    />
                </Box>
                <Box>
                    {filteredUsers?.map((row, index) => {
                        return (
                            <DistributionCard
                                asset={row}
                                key={index}
                                mappings={mappings}
                                primaryMappings={primaryMappings}
                                handleOnResetClick={handleOnResetClick}
                                generics={generics}
                                setMappings={setMappings}
                                selectedScenario={selectedScenario}
                            />
                        );
                    })}
                </Box>
                {['testator', 'partner']?.includes(user?.role) && (
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 50,
                            left: 146,
                            right: 146,
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <BottomFloatingAction
                            message="Would you like to save or reset the distribution?"
                            firstButtonText="Cancel"
                            onFirstButtonClick={handleCancelDistribution}
                            firstButtonDisabled={beneficiaryNotAdded}
                            firstButtonSx={{
                                minWidth: '75px',
                                padding: '6px 0',
                                border: `1px solid ${theme.palette.primary.main}40`,
                                // height: '30px',
                            }}
                            secondButtonText="Reset"
                            onSecondButtonClick={handleResetDistribution}
                            secondButtonDisabled={beneficiaryNotAdded}
                            secondButtonSx={{ minWidth: '75px', padding: '6px 0', }}
                            thirdButtonText="Save Distribution"
                            onThirdButtonClick={handleSaveDistribution}
                            thirdButtonDisabled={beneficiaryNotAdded || distributionError}
                            thirdButtonSx={{ padding: '6px 12px', }}
                        />
                    </Box>  
                )}
            </Box>
            <SelectDistributionModal
                open={selectDistributionModalOpen}
                onClose={handleOnClose}
                generics={generics}
                setMappings={setMappings}
                filteredUsers={filteredUsers}
            />
            <AlertDialog
                open={isAlertDialogOpen}
                onClose={handleAlertDialogClose}
                title={`Are you sure you want to reset this asset?`}
                primaryButtonLabel="Keep"
                secondaryButtonLabel="Reset"
                onPrimaryButtonClick={handleAlertDialogClose}
                onSecondaryButtonClick={handleResetConfirmation}
            />
            <AlertDialog
                open={confirmDistributionAlertOpen}
                onClose={handleConfirmAlertDialogClose}
                title={`Are you sure you want to change distribution method?`}
                primaryButtonLabel="Keep"
                secondaryButtonLabel="Change"
                onPrimaryButtonClick={handleConfirmAlertDialogClose}
                onSecondaryButtonClick={handleConfirmAlertConfirmation}
            />
        </Box>
    );
};

export default PrimaryDistribution;
