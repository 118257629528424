import { Box, Divider, Tab, Tabs, Typography, useTheme } from '@mui/material';
import DeleteAccountSection from "./DeleteAccountSection";
import LogoutSection from "./LogoutSection";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Sidebar = ({ value, handleTabChange }) => {
  const theme = useTheme();

  const handlePrivacyPolicyClick = () => {
    window.open('https://getwilldone.com/privacy-policy/', 'Get Will Done');
  };

  return (
    <>
      <Box
        sx={{
          width: '250px',
          height: '80vh',
          paddingLeft: '5px',
          marginTop: '9px',
          display: 'flex',
          flexDirection: 'row', // Change to row to place tabs and divider side by side
          justifyContent: 'flex-start', // Align items to the start
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between', // Ensures footer stays at the bottom
            flexGrow: 1, // Allow this Box to take up the remaining space
          }}
        >
          <Box>
            <Typography variant="title1">Profile</Typography>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleTabChange}
              aria-label="Vertical tabs example"
              sx={{
                // borderRight: 1,
                // borderColor: theme.palette.grey[100],
                height: '100%', // Adjust height to account for footer
                '& .MuiTabs-indicator': {
                  display: 'none', // Hide the indicator for the selected tab
                },
                '& .MuiTab-root': {
                  textTransform: 'none', // Remove default text transformation
                  alignItems: 'flex-start',
                },
              }}
            >
              <Tab
                label={<Typography variant="bodyText3">Personal Details</Typography>}
                {...a11yProps(0)}
                sx={{
                  width: value === 0 ? '182px' : 'auto',
                  height: value === 0 ? '40px' : 'auto',
                  borderRadius: value === 0 ? '30px' : '0px',
                  border: value === 0 ? `1px solid ${theme.palette.primary.light}` : 'none',
                  backgroundColor: value === 0 ? theme.palette.primary.lighter : 'transparent',
                  opacity: value === 0 ? 1 : 'inherit',
                  mb: 1, // Margin between tabs
                  mt: '2.310rem',
                }}
              />
              <Tab
                label={<Typography variant="bodyText3">Address</Typography>}
                {...a11yProps(1)}
                sx={{
                  width: value === 1 ? '182px' : 'auto',
                  height: value === 1 ? '40px' : 'auto',
                  borderRadius: value === 1 ? '30px' : '0px',
                  border: value === 1 ? `1px solid ${theme.palette.primary.light}` : 'none',
                  backgroundColor: value === 1 ? theme.palette.primary.lighter : 'transparent',
                  opacity: value === 1 ? 1 : 'inherit',
                  mb: 1, // Margin between tabs
                }}
              />
              <Tab
                label={<Typography variant="bodyText3">Existing Will</Typography>}
                {...a11yProps(2)}
                sx={{
                  width: value === 2 ? '182px' : 'auto',
                  height: value === 2 ? '40px' : 'auto',
                  borderRadius: value === 2 ? '30px' : '0px',
                  border: value === 2 ? `1px solid ${theme.palette.primary.light}` : 'none',
                  backgroundColor: value === 2 ? theme.palette.primary.lighter : 'transparent',
                  opacity: value === 2 ? 1 : 'inherit',
                  mb: 1, // Margin between tabs
                }}
              />
              <Tab
                label={<Typography variant="bodyText3">Purchase History</Typography>}
                {...a11yProps(3)}
                sx={{
                  width: value === 3 ? '182px' : 'auto',
                  height: value === 3 ? '40px' : 'auto',
                  borderRadius: value === 3 ? '30px' : '0px',
                  border: value === 3 ? `1px solid ${theme.palette.primary.light}` : 'none',
                  backgroundColor: value === 3 ? theme.palette.primary.lighter : 'transparent',
                  opacity: value === 3 ? 1 : 'inherit',
                  mb: 1, // Margin between tabs
                }}
              />
              <Tab
                label={<Typography variant="bodyText3">Version History</Typography>}
                {...a11yProps(4)}
                sx={{
                  width: value === 4 ? '182px' : 'auto',
                  height: value === 4 ? '40px' : 'auto',
                  borderRadius: value === 4 ? '30px' : '0px',
                  border: value === 4 ? `1px solid ${theme.palette.primary.light}` : 'none',
                  backgroundColor: value === 4 ? theme.palette.primary.lighter : 'transparent',
                  opacity: value === 4 ? 1 : 'inherit',
                  mb: 1, // Margin between tabs
                }}
              />
            </Tabs>
          </Box>

          {/* Footer Links */}
          <Box
            sx={{
              pt: 0.5, // Slight top padding to maintain some spacing while raising the links
              pb: 0, // No bottom padding
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <LogoutSection />
            <Typography
              variant="suggestiveText"
              onClick={handlePrivacyPolicyClick}
              sx={{
                color: theme.palette.grey[300],
                textDecoration: 'none',
                mb: 1,
                cursor: 'default',
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: theme.palette.grey[400],
                },
              }}
            >
              Privacy Policy
            </Typography>
            <DeleteAccountSection />
          </Box>
        </Box>
        <Divider
          orientation="vertical"
          sx={{ height: '93%', width: '1px', mx: '20px', color: '#C5C5C5', mt: 8 }} // Ensure it spans the full height of the container
        />
      </Box>
    </>
  );
};

export default Sidebar;
