import { useTheme } from '@emotion/react';
import { Backdrop, Box, Button, CircularProgress, Divider, FormControl, FormHelperText, Grid, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ContextProvider } from 'App';
import AlertDialog from 'components/DesignSystem/AlertDialog';
import ButtonCheckBox from 'components/DesignSystem/ButtonCheckBox';
import { Dropdown } from 'components/DesignSystem/DropDown';
import Icon from 'components/DesignSystem/Icon';
import useSwalAlert from 'components/DesignSystem/SwalAlert/SwalAlert';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { checkPaymentStatusApi, checkValidatePartnerApi, fetchActiveCoupons, fetchServices, RequestForQuote, subscribeApi, validateCoupon } from 'services/SubscriptionServicesPage';
import { formatPrice } from 'utils/formatPrice';
import { ProfileIcon } from 'views/dashboard/PageContainer';
import { ContainedButton1, TextedButton2 } from '../../components/DesignSystem/Button';
import CustomTextField from '../../components/DesignSystem/InputField';
import GwdImageContentCard from './GwdImageContentCard';
import ServiceDetailCard from './ServiceDetailCard';
// Styled Components
const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

export default function ServicesPage() {
    const theme = useTheme();
    const navigate = useNavigate();
    const showSwalAlert = useSwalAlert();
    const [loading, setLoading] = useState(false);
    const { user, fetchUser } = useContext(ContextProvider);
    const [subscriptions, setSubscriptions] = useState([]);
    const [coupons, setCoupons] = useState([]);
    const [offerCoupons, setOfferCoupns] = useState([]);
    const [offer, setOffer] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [checkedSubscriptions, setCheckedSubscriptions] = useState([]);
    const [disableCouponCode, setDisableCouponCode] = useState(false);
    const [couponCodeError, setCouponCodeError] = useState(null);
    const [couponCodeApplied, setCouponCodeApplied] = useState(null);
    const [partnerDiscountApplicable, setPartnerDiscountApplicable] = useState(false);
    const [appliedPartnerCodeDiscount, setAppliedPartnerCodeDiscount] = useState(0);
    const [appliedCouponCode, setAppliedCouponCode] = useState([]);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [confirmSub, setConfirmSub] = useState({
        open: false,
        title: '',
        text: '',
        sub: {},
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const servicesResponse = await fetchServices();
                setSubscriptions(servicesResponse.data?.subscriptions ?? []);

                const couponsResponse = await fetchActiveCoupons();
                setCoupons(couponsResponse.data?.coupons ?? []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchUser();
        fetchData();
    }, []);

    useEffect(() => {
        coupons.map(coupon => console.log('filter condition', coupon.startDate, new Date(coupon.startDate), new Date()));
        if (coupons.length === 0)
            setOfferCoupns([]);
        else
            setOfferCoupns(coupons.filter(coupon => new Date(coupon.startDate) <= new Date().setHours(0, 0, 0, 0) && coupon.type === 'date'));
    }, [coupons]);

    const onOfferChange = (e) => {
        if (e.target.value === "") {
            setDisableCouponCode(Boolean(false));
            setAppliedCouponCode([]);
        }
        else {
            setAppliedCouponCode(coupons.filter(coupon => coupon.id === +e.target.value));
            setDisableCouponCode(Boolean(true));
        }
        setOffer(e.target.value);
    };

    const handleBuyClick = (subscription) => {
        const idExists = checkedSubscriptions.some((item) => item.id === subscription.id);
        if (idExists) {
            // If already checked, remove it from the checkedSubscriptions array
            setCheckedSubscriptions(checkedSubscriptions.filter((item) => item.id !== subscription.id));
        } else {
            // If not checked, add it to the checkedSubscriptions array
            setCheckedSubscriptions([...checkedSubscriptions, subscription]);
        }
    };

    const getSubscribedExpiry = useMemo(() => {
        if (user?.Subscriptions?.length > 0) {
            const makeWillSub = user?.Subscriptions?.find((sub) => sub.id === 1);
            console.log(makeWillSub);
            if (makeWillSub)
                return makeWillSub.UserSubscription.SubscriptionId === 1 &&
                    makeWillSub.UserSubscription.active === true &&
                    makeWillSub.UserSubscription.expires_at !== null
                    ? makeWillSub.UserSubscription.expires_at
                    : null;
        }
        return null;
    }, [user]);

    const allowCalls = useMemo(() => {
        const callCount = user?.Reviews?.filter((rev) => rev.type === 'CALL')?.length ?? 0;
        const allowed = user?.Subscriptions?.reduce((p, c) => +p + +(c.calls ?? 0), 0);
        if (callCount < allowed) return true;
        return false;
    }, [user]);

    const registerRequest = (row) => {
        let type = 'CALL';
        let message = '';
        switch (true) {
            case row.reviews > 0:
                type = 'REVIEW';
                message =
                    'I agree to share my details and copy of my Will with GWD Systems Private Limited and the team of lawyers / associates for review of the Will.';
                break;
            case row.registration > 0:
                type = 'REGISTRATION';
                message =
                    'I agree to share my details and copy of my Will with GWD Systems Private Limited and the team of lawyers / associates for review of the Will.';
                break;
            case row.notarization > 0:
                type = 'NOTARIZATION';
                message =
                    'I agree to share my details and copy of my Will with GWD Systems Private Limited and the team of lawyers / associates for review of the Will.';
                break;
            case row.calls > 0:
                type = 'CALL';
                message = `I agree to share my details and copy of my Will with GWD Systems Private Limited and the team of lawyers / associates for the purpose of seeking assistance in relation to creation of Will. <p><strong><span style="color:red">There will be only 1 call & it would last not more than 30 minutes.</span></strong></p>`;
                break;
            default:
                type = 'CALL';
                break;
        }

        if (type === 'REVIEW' || type === 'CALL') {
            setConfirmSub({
                open: true,
                title: `Consent for ${row.name}`,
                text: message,
                sub: row,
                type,
            });
        } else {
            setConfirmSub({
                open: true,
                title: `Get a Quote for ${row.name}`,
                text: message,
                sub: row,
                type,
            });
        }
    };

    const activeCall = useMemo(() => {
        let active = false;
        if (user?.Reviews?.find((rev) => rev.status !== 'CLOSED' && rev.type === 'CALL')) active = true;
        return active;
    }, [user]);

    const activeReview = useMemo(() => {
        let active = false;
        if (
            user?.Reviews?.find(
                (rev) => rev.status !== 'CLOSED' && rev.will_version === user?.will_version && rev.type === 'REVIEW'
            )
        )
            active = true;
        return active;
    }, [user]);

    const activeRegistration = useMemo(() => {
        let active = false;
        if (
            user?.Reviews?.find(
                (rev) => rev.status !== 'CLOSED' && rev.will_version === user?.will_version && rev.type === 'REGISTRATION'
            )
        )
            active = true;
        return active;
    }, [user]);

    const activeNotarization = useMemo(() => {
        let active = false;
        if (
            user?.Reviews?.find(
                (rev) => rev.status !== 'CLOSED' && rev.will_version === user?.will_version && rev.type === 'NOTARIZATION'
            )
        )
            active = true;
        return active;
    }, [user]);

    const subscribed = useMemo(() => {
        let consumed = true;
        // let consumed = user?.Subscriptions ?.length === 0;
        user?.Subscriptions?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).forEach((sub) => {
            if (sub) {
                const userSub = sub.UserSubscription;
                if (userSub && userSub.SubscriptionId === 1 && userSub.payment_status === 'Success') {
                    // const today = new Date();
                    // const subDay = new Date(userSub.createdAt);
                    // const subDays = (today - subDay) / 86400 / 1000;
                    // const allowed = sub.days;
                    // if (subDays > allowed) consumed = true;
                    // else consumed = false;
                    if (userSub.active === true) consumed = false;
                    else consumed = true;
                }
                if (user.role === 'partner' && userSub && userSub.SubscriptionId === 1 && userSub.payment_status === 'DEMO') {
                    // setDemoModeActivated(true);
                }
            }
        });
        return !consumed;
    }, [user]);

    useEffect(() => {
        if (user.referral_code !== null && user.referral_code !== undefined) {
            checkValidatePartnerApi(user.referral_code).then(res => {
                if (res.data.valid) {
                    if (res.data.consumed) {
                        setPartnerDiscountApplicable(false);
                    }
                    else {
                        const { discount } = res.data;
                        if (discount > 0) {
                            setAppliedPartnerCodeDiscount(discount);
                            setPartnerDiscountApplicable(true);
                        }
                        else {
                            setAppliedPartnerCodeDiscount(0);
                            setPartnerDiscountApplicable(false);
                        }
                    }
                }
                else {
                    setPartnerDiscountApplicable(false);
                }
            }).catch((e) => {
                console.log('error', e);
                setPartnerDiscountApplicable(false);
            });
        }
    }, [user]);

    const handleClose = () => {
        setConfirmSub({
            open: false,
            title: '',
            text: '',
            sub: {},
        });
    };

    const handleCouponCodeChange = async (event) => {
        const { value } = event.target;
        setCouponCode(value);
    };

    const removeCouponCode = () => {
        setCouponCodeApplied(null);
        setCouponCode('');
        setDisableCouponCode(Boolean(false));
        setCouponCodeError(null);
        setAppliedCouponCode([]);
    };

    const onPurchaseClick = (row) => {
        if (
            (checkedSubscriptions.some((item) => item.id === 2) || row.id !== 2) &&
            (checkedSubscriptions.some((item) => item.id === 2) || row.id !== 2) &&
            (checkedSubscriptions.some((item) => item.id === 5) || row.id !== 5)
        ) {
            handleBuyClick(row);
        } else {
            registerRequest(row);
        }
    };

    const applyCouponCode = async () => {
        if (couponCode !== '') {
            try {
                const res = await validateCoupon(couponCode);
                if (res.data.valid) {
                    if (res.data.consumed) {
                        setCouponCodeError('This Coupon Code is consumed');
                        setCouponCodeApplied('Consumed');
                    } else {
                        const { couponId } = res.data;
                        if (couponId > 0) {
                            setAppliedCouponCode(res.data.coupon);
                        }
                        setCouponCodeApplied('Applied');
                        setDisableCouponCode(Boolean(true));
                        setCouponCodeError('Coupon Code applied successfully');

                        if (res.data.isDemoCoupon) {
                            fetchUser();
                            showSwalAlert({
                                title: `DEMO mode activated successfully`,
                                icon: 'success',
                                timer: 2000,
                            });
                        }
                    }
                } else {
                    setCouponCodeApplied('Invalid');
                    setCouponCodeError('Invalid Coupon Code');
                }
            } catch (error) {
                console.log('error', error);
                setCouponCodeApplied('Invalid');
                setCouponCodeError('Something went wrong');
            }
        }
    };

    // Calculate the total price of selected products
    const calculateTotal = () => {
        return checkedSubscriptions
            .filter((subscription) => subscriptions.filter((item) => item.id === subscription.id))
            .reduce((total, subscription) => {
                let isApplicable = false;
                const { price, basePrice, igstPercentage, cgstPercentage, sgstPercentage } = subscription;
                let offerPrice = +basePrice;
                let originalBasePrice = +basePrice;

                let discount = 0;
                if (appliedCouponCode.length > 0) {
                    isApplicable = appliedCouponCode[0].applicableServices.includes(subscription.id);
                }
                if (isApplicable) {
                    discount = appliedCouponCode[0].discount;
                    offerPrice = ((originalBasePrice * (100 - discount)) / 100);
                }
                else if (partnerDiscountApplicable && subscription.id === 1) {
                    discount = appliedPartnerCodeDiscount;
                    offerPrice = ((originalBasePrice * (100 - discount)) / 100);
                }
                if (user.profile.permanent.state.toLowerCase() === 'maharashtra' || user.profile.permanent.state.toLowerCase() === 'mh') {
                    originalBasePrice = Math.round((+offerPrice + (+offerPrice * (+cgstPercentage + +sgstPercentage) / 100)));
                    offerPrice = originalBasePrice;
                }
                else {
                    originalBasePrice = Math.round((+offerPrice + ((+offerPrice * (+igstPercentage)) / 100)));
                    offerPrice = originalBasePrice;
                }
                return total + (offerPrice);
            }, 0
            );
    };

    const subscribe = () => {
        const subscriptionIds = checkedSubscriptions.map((item) => item.id);

        subscribeApi({ couponCode, offer, subscriptionIds }).then(res => {
            const userSubscription = res.data.order_id;

            // console.log(res.data.paymentUrl);
            // setPaymentURL(res.data.paymentUrl);
            setShowBackdrop(true);
            // Open CCAvenue in a new tab
            const paymentWindow = window.open(res.data.paymentUrl, '_blank');

            const checkPaymentStatus = (isTimeout = false) => {
                checkPaymentStatusApi(userSubscription).then(res => {
                    if (res.data.userSubscription.payment_status !== "PENDING" || isTimeout) {
                        setShowBackdrop(false);
                        clearInterval(interval);
                        clearTimeout(backdropTimeout);
                        switch (res.data.userSubscription.payment_status) {
                            case 'Success':

                                showSwalAlert({
                                    title: `Purchase is completed!`,
                                    icon: 'success',
                                    timer: 15000
                                });
                                break;
                            case 'Failure':
                                showSwalAlert({
                                    icon: "error",
                                    title: `Purchase is failed!`,
                                    timer: 15000
                                });
                                break;
                            case 'Aborted':
                                showSwalAlert({
                                    icon: "error",
                                    title: `Purchase is cancelled!`,
                                    timer: 15000
                                });
                                break;
                            case 'Invalid':

                                showSwalAlert({
                                    icon: "error",
                                    title: `Purchase is invalid!`,
                                    timer: 15000
                                });
                                break;
                            case 'PENDING':
                                showSwalAlert({
                                    icon: "warning",
                                    title: `Purchase is still Pending, You can reinitiate payment!`,
                                    timer: 15000
                                });
                                break;
                            default:
                                showSwalAlert({
                                    icon: "error",
                                    title: `Purchase is unknown!`,
                                    timer: 15000
                                });
                                break;
                        }
                        calculateTotal();
                        setCheckedSubscriptions([]);
                        removeCouponCode();
                        if (res.data.userSubscription.payment_status === 'Success') {
                            fetchUser();
                        }
                    }
                }).catch((e) => {
                    clearInterval(interval);
                    setShowBackdrop(false);
                    setCheckedSubscriptions([]);
                    clearTimeout(backdropTimeout);
                    removeCouponCode();

                    showSwalAlert({
                        icon: "error",
                        title: `Failed to complete request. Please retry.`,
                        timer: 3000
                    });
                    fetchUser();
                });
            }
            // Periodically check for payment completion
            const interval = setInterval(() => {
                checkPaymentStatus();
                // if (sessionStorage.getItem('paymentCompleted')) {
                //     setShowBackdrop(false);
                //     sessionStorage.removeItem('paymentCompleted');
                //     toastr.success("Your payment was successful", { timeOut: 0 })
                //     // Payment is completed, close the payment window and clear interval
                //     // paymentWindow.close();
                //     clearInterval(interval);
                //     clearTimeout(backdropTimeout);
                // }
            }, 6000);

            // Periodically check for payment completion
            const backdropTimeout = setTimeout(() => {
                setShowBackdrop(false);
                clearInterval(interval);
                showSwalAlert({
                    icon: "info",
                    title: `Checking payment status`,
                    timer: 3000
                });
                checkPaymentStatus(true);
                // navigate('/dashboard/app', {replace: true});
            }, 300000);

        }).catch(e => {
            showSwalAlert({
                icon: "error",
                title: `Unable to purchase product`,
                timer: 3000
            });
            fetchUser();
        });
    };

    return (
        <>
            <Helmet>
                <title>Our Services | Get Will Done </title>
            </Helmet>
            <AlertDialog
                open={confirmSub.open}
                onClose={() => {
                    handleClose();
                }}
                modelTitle={confirmSub.title}
                primaryButtonLabel={'Cancel'}
                secondaryButtonLabel={confirmSub.type === 'REVIEW' || confirmSub.type === 'CALL' ? 'Confirm' : 'Get a Quote'}
                onPrimaryButtonClick={() => {
                    handleClose();
                }}
                onSecondaryButtonClick={
                    confirmSub.type === 'REVIEW' || confirmSub.type === 'CALL'
                        ? () => {
                            handleClose();
                            handleBuyClick(confirmSub.sub);
                        }
                        : async () => {
                            if (loading) return;
                            setLoading(true);
                            try {
                                await RequestForQuote(confirmSub.type);
                                showSwalAlert({
                                    title: `Request to Get a Quote submitted successfully`,
                                    icon: 'success',
                                    timer: 2000,
                                });
                            } catch (error) {
                                showSwalAlert({
                                    title: `Failed to request a call`,
                                    icon: 'error',
                                    timer: 2000,
                                });
                            } finally {
                                setLoading(false);
                                handleClose();
                                fetchUser();
                            }
                        }
                }
                onSecondaryButtonDisabled={loading}
            >
                <Box sx={{ bgcolor: 'white' }}>
                    <Typography variant="bodyText4" color={theme.palette.grey[400]} component="p" sx={{ mb: 2 }}>
                        <div dangerouslySetInnerHTML={{ __html: confirmSub.text }} />
                    </Typography>
                </Box>
            </AlertDialog>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showBackdrop}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                }} >
                    <CircularProgress color="primary" size={80} thickness={4} />
                    <Typography variant="heading1" sx={{
                        marginTop: 2,
                    }}>
                        Waiting for response from payment gateway...
                    </Typography>
                </Box>
            </Backdrop>
            <Box>
                <Box p={'0 50px'}>
                    {' '}
                    {/* header */}
                    <Box sx={{ minHeight: '130px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                            <Icon name="will-logo.png" alt="Will Logo" height={77} width={154} />
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <TextedButton2
                                sx={{
                                    width: '160px',
                                    height: '45px',
                                    marginRight: theme.spacing(2),
                                }}
                                onClick={() => navigate('/landingPage')}
                            >
                                <Typography variant="bodyText4" sx={{ color: theme.palette.secondary.main, fontWeight: 'bold', }} onClick={() => navigate('/landingPage')}>
                                    Go to Dashboard
                                </Typography>
                            </TextedButton2>
                            <ProfileIcon />
                        </Box>
                    </Box>
                    <Divider sx={{ color: theme.palette.grey[100] }} />
                </Box>
                <StyledRoot>
                    <Grid container>
                        {/* Left Grid: Service Form */}
                        <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: 'flex-start', backgroundColor: '#fff', boxShadow: '10px 0px 18px 0px rgba(0, 0, 0, 0.04)', zIndex: 11, }}>
                            <Box sx={{ width: '100%', maxWidth: '85%', margin: '0 auto', padding: '48px 0' }}>
                                <Box>
                                    {/* First Box with Typography */}
                                    <Box sx={{ mb: 3 }}>
                                        <Typography variant="title1" color={theme.palette.primary.main} gutterBottom>
                                            Make your Own Will
                                        </Typography>
                                    </Box>

                                    {/* Second Box with Typography */}
                                    <Box sx={{ mb: '20px', maxWidth: '94%' }}>
                                        <Typography variant="bodyText4" color={theme.palette.grey[400]}>
                                            Create a complete digital Will with GetWillDone. Get a one-year subscription with unlimited edits
                                            and resources to guide you, along with expert help from our lawyers.
                                        </Typography>
                                    </Box>

                                    {subscriptions
                                        .sort((a, b) => a.id - b.id)
                                        .filter((row) => [1].includes(row.id))
                                        .map((row) => {
                                            let price = Math.round(+row.basePrice + (+row.basePrice * +row.igstPercentage) / 100);
                                            let discountedPrice =
                                                +row.discountedPrice > -1
                                                    ? Math.round(+row.discountedPrice + (+row.discountedPrice * +row.igstPercentage) / 100)
                                                    : +row.discountedPrice;

                                            if (
                                                user.profile.permanent.state.toLowerCase() === 'maharashtra' ||
                                                user.profile.permanent.state.toLowerCase() === 'mh'
                                            ) {
                                                price = Math.round(
                                                    +row.basePrice + (+row.basePrice * (+row.cgstPercentage + +row.sgstPercentage)) / 100
                                                );
                                                discountedPrice =
                                                    +row.discountedPrice > -1
                                                        ? Math.round(+row.discountedPrice + (+row.discountedPrice * +row.igstPercentage) / 100)
                                                        : +row.discountedPrice;
                                            }
                                            return (
                                                <GwdImageContentCard
                                                    price={price}
                                                    discountedPrice={discountedPrice}
                                                    row={row}
                                                    getSubscribedExpiry={getSubscribedExpiry}
                                                    subscribed={subscribed}
                                                    user={user}
                                                    checkedSubscriptions={checkedSubscriptions}
                                                    onPurchaseClick={onPurchaseClick}
                                                />
                                            );
                                        })}

                                    <Box sx={{ mb: '20px' }}>
                                        <Typography variant="title2" color={theme.palette.grey[600]}>
                                            Value Added Services
                                        </Typography>
                                    </Box>
                                    {subscriptions
                                        .filter((row) => [5, 2].includes(row.id))
                                        .sort((a, b) => {
                                            if (a.id === 5) return -1; // Prioritize ID 5
                                            if (b.id === 5) return 1; // Prioritize ID 5
                                            if (a.id === 2) return -1; // Prioritize ID 2
                                            if (b.id === 2) return 1; // Prioritize ID 2
                                            return 0; // Keep the rest in original order
                                        })
                                        .map((row) => {
                                            let price = Math.round(+row.basePrice + (+row.basePrice * +row.igstPercentage) / 100);
                                            let discountedPrice =
                                                +row.discountedPrice > -1
                                                    ? Math.round(+row.discountedPrice + (+row.discountedPrice * +row.igstPercentage) / 100)
                                                    : +row.discountedPrice;
                                            const isCheckBoxVisible =
                                                (row.id === 1 && !subscribed) ||
                                                (row.id === 2 && !activeReview) ||
                                                (row.id === 3 && !activeNotarization) ||
                                                (row.id === 4 && !activeRegistration) ||
                                                (row.id === 5 && !activeCall);
                                            if (
                                                user.profile.permanent.state.toLowerCase() === 'maharashtra' ||
                                                user.profile.permanent.state.toLowerCase() === 'mh'
                                            ) {
                                                price = Math.round(
                                                    +row.basePrice + (+row.basePrice * (+row.cgstPercentage + +row.sgstPercentage)) / 100
                                                );
                                                discountedPrice =
                                                    +row.discountedPrice > -1
                                                        ? Math.round(+row.discountedPrice + (+row.discountedPrice * +row.igstPercentage) / 100)
                                                        : +row.discountedPrice;
                                            }
                                            return (
                                                <ServiceDetailCard
                                                    title={`${row.name}`}
                                                    subtitle={
                                                        row.id === 5
                                                            ? 'Get all your Will-related questions answered by a lawyer in a 30-minute session.'
                                                            : 'Get your Will reviewed by lawyer and all questions answered by a lawyer in one session.'
                                                    }
                                                    price={price}
                                                    row={row}
                                                    activeCall={activeCall}
                                                    activeReview={activeReview}
                                                    user={user}
                                                    checkedSubscriptions={checkedSubscriptions}
                                                    onPurchaseClick={onPurchaseClick}
                                                    isCheckBoxVisible={isCheckBoxVisible}
                                                />
                                            );
                                        })}
                                    <Divider sx={{ my: '20px', borderStyle: 'dashed', borderColor: '#C5C5C5' }} />
                                    {subscriptions
                                        .sort((a, b) => a.id - b.id)
                                        .filter((row) => [3, 4].includes(row.id))
                                        .map((row) => (
                                            <Grid key={row.id} item xs={12}>
                                                <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px', backgroundColor: theme.palette.secondary.lighter, border: `1px solid ${theme.palette.secondary.lighter}`, borderRadius: '10px' }}>
                                                    <Box>
                                                        <Typography variant="bodyText3" color={theme.palette.grey[600]}>
                                                            {`${row.name}`}
                                                        </Typography>
                                                        <Typography variant="suggestiveText" color={theme.palette.grey[400]}>
                                                            <Box component="span" sx={{ display: 'block' }}>
                                                                {row.id === 3 ? 'Get your Will notarised at your doorstep. (Currently available only in Pune.)' : 'Get your Will registered with the authorities, easily & hassle-free.'}
                                                            </Box>
                                                        </Typography>
                                                    </Box>
                                                    <Stack direction={'column'} spacing={0}>
                                                        <ButtonCheckBox
                                                            checked={checkedSubscriptions.some((checkedSubscription) => checkedSubscription.id === row.id)}
                                                            onChange={() => { registerRequest(row) }}
                                                            disabled={
                                                                !((row.id === 3 && !activeNotarization) || (row.id === 4 && !activeRegistration)) ||
                                                                (user.role === 'partner' && user.partner_enroll_status !== 'APPROVED')
                                                            }
                                                            bgColor="transparent"
                                                            RequestForQuote
                                                        />
                                                        {row.calls > 0 && allowCalls && (
                                                            <Typography variant="caption" textAlign={'center'} color={'success.main'}>
                                                                You already have a free call. You can click "Request a Call" at the top right corner to
                                                                proceed.
                                                            </Typography>
                                                        )}
                                                        {row.calls > 0 && activeCall && (
                                                            <Typography variant="caption" textAlign={'center'} color={'error.main'}>
                                                                You already have an active call. Please wait for our call.
                                                            </Typography>
                                                        )}
                                                        {activeReview && row.id === 2 && (
                                                            <Typography variant="caption" textAlign={'center'} color={'error.main'}>
                                                                Please call on +919156888418 to avail the services.
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                </Box>
                                            </Grid>
                                        ))}
                                </Box>
                            </Box>
                        </Grid>
                        {/* Right Grid: Cart */}
                        <Grid item xs={12} md={5} sx={{ backgroundColor: '#f9f9f9', backgroundImage: 'url(/assets/Service-Cart.svg)', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                            <Box sx={{ border: `1px solid #e0e0e0`, borderRadius: '20px', p: '25px 20px 18px', backgroundColor: '#fff', marginTop: '128px', marginX: '32px' }} >
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="title2">My Cart</Typography>
                                </Box>
                                <Box>
                                    {user.referral_code === null && (
                                        <Box>
                                            {(user.role === "testator" || (user.role === 'partner' && user.partner_enroll_status === "APPROVED")) && offerCoupons.length > 0 && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Dropdown
                                                            name="choose_offer"
                                                            value={offer}
                                                            options={offerCoupons.map(offerCoupon => ({
                                                                value: offerCoupon.id,
                                                                label: offerCoupon.name,
                                                            }))}
                                                            placeholder="Choose Offer"
                                                            handleChange={onOfferChange}
                                                            disabled={couponCode !== '' ? Boolean(true) : Boolean(false)}
                                                        />
                                                    </Grid>
                                                    <Box textAlign='center'>
                                                        <Typography variant="bodyText4" sx={{ m: 1 }}>
                                                            OR
                                                        </Typography>
                                                    </Box>
                                                </>
                                            )}
                                            <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                                                <FormControl
                                                    required
                                                    error={couponCodeError === 'Coupon Code applied successfully' ? Boolean(false) : Boolean(true)}
                                                    variant="standard"
                                                >
                                                    <CustomTextField
                                                        variant="outlined"
                                                        placeholder="Coupon Code"
                                                        sx={{ height: '40px' }}
                                                        value={couponCode}
                                                        onChange={handleCouponCodeChange}
                                                        disabled={disableCouponCode}
                                                    />
                                                    <FormHelperText sx={{ alignSelf: 'end', fontFamily: 'DM Sans, sans-serif', fontStyle: 'italic', color: couponCodeError === 'Coupon Code applied successfully' ? 'success.main' : 'error', mt: 1 }}>
                                                        {couponCodeError}
                                                    </FormHelperText>
                                                </FormControl>

                                                {couponCodeApplied !== 'Applied' && (
                                                    <Button onClick={applyCouponCode} disabled={disableCouponCode || checkedSubscriptions.length === 0} variant={'text'} sx={{ marginLeft: '10px', marginBottom: 0, textDecoration: 'underline', color: theme.palette.secondary.main, textTransform: 'none', '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline', color: theme.palette.secondary.main, }, '&:focus': { outline: 'none' } }}>
                                                        <Typography variant="bodyText3">Apply</Typography>
                                                    </Button>
                                                )}
                                                {couponCodeApplied === 'Applied' && (
                                                    <Button onClick={removeCouponCode} variant={'text'} sx={{ marginLeft: '10px', marginBottom: 0, textDecoration: 'underline', textTransform: 'none', '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline', }, '&:focus': { outline: 'none', }, }} >
                                                        <Typography variant="bodyText3">Clear</Typography>
                                                    </Button>
                                                )}
                                            </Box>
                                        </Box>
                                    )}
                                    {checkedSubscriptions.length > 0 && (
                                        <List dense>
                                            {checkedSubscriptions
                                                .sort((a, b) => a.id - b.id)
                                                .map((checkedSubscription, index) => {
                                                    console.log('appliedCouponCode', appliedCouponCode);
                                                    const { basePrice, igstPercentage, cgstPercentage, sgstPercentage } = checkedSubscription;

                                                    let originalBasePrice = +basePrice;
                                                    let offerPrice = +basePrice;

                                                    let discount = 0;
                                                    let isApplicable = false;
                                                    let isPartnerDiscountApplicable = false;
                                                    if (appliedCouponCode.length > 0) {
                                                        isApplicable = appliedCouponCode[0].applicableServices.includes(checkedSubscription.id);
                                                    }

                                                    if (isApplicable) {
                                                        discount = appliedCouponCode[0].discount;
                                                        offerPrice = (+originalBasePrice * (100 - discount)) / 100;
                                                    } else if (partnerDiscountApplicable && checkedSubscription.id === 1) {
                                                        discount = appliedPartnerCodeDiscount;
                                                        isPartnerDiscountApplicable = true;
                                                        offerPrice = (+originalBasePrice * (100 - discount)) / 100;
                                                    }

                                                    if (
                                                        user.profile.permanent.state.toLowerCase() === 'maharashtra' ||
                                                        user.profile.permanent.state.toLowerCase() === 'mh'
                                                    ) {
                                                        originalBasePrice = Math.round(
                                                            +originalBasePrice + (+originalBasePrice * (+cgstPercentage + +sgstPercentage)) / 100
                                                        );
                                                        offerPrice = Math.round(
                                                            +offerPrice + (+offerPrice * (+cgstPercentage + +sgstPercentage)) / 100
                                                        );
                                                    } else {
                                                        originalBasePrice = Math.round(
                                                            +originalBasePrice + (+originalBasePrice * +igstPercentage) / 100
                                                        );
                                                        offerPrice = Math.round(+offerPrice + (+offerPrice * +igstPercentage) / 100);
                                                    }

                                                    return (
                                                        <Box key={checkedSubscription.id}>
                                                            <ListItem
                                                                key={checkedSubscription.id}
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }} >
                                                                            <Typography variant="bodyText3" color={theme.palette.grey[400]}>
                                                                                {checkedSubscription.name}
                                                                            </Typography>
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                                                                                <Typography variant="bodyText4" color={theme.palette.grey[600]}>
                                                                                    <span style={{ textDecoration: isApplicable ? 'line-through' : 'none' }}>
                                                                                        ₹{formatPrice(originalBasePrice)}
                                                                                    </span>
                                                                                    {isApplicable || isPartnerDiscountApplicable ? (
                                                                                        <span style={{ color: 'green' }}>
                                                                                            &nbsp;₹{formatPrice(offerPrice)} ({discount}% off)
                                                                                        </span>
                                                                                    ) : null}
                                                                                </Typography>
                                                                                <Icon name='delete-icon-orange.svg' height='40px' width='40px' onClick={() => handleBuyClick(checkedSubscription)} />
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            {index < checkedSubscriptions.length - 1 && (
                                                                <Divider variant="middle" sx={{ mt: 1, mb: 1, borderStyle: 'dashed' }} />
                                                            )}
                                                        </Box>
                                                    );
                                                })}
                                            <Divider sx={{ mt: 1, mb: 1, borderColor: theme.palette.grey[100] }} />
                                        </List>
                                    )}
                                </Box>
                                {/* Total Amount */}
                                <Box
                                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '16px', marginRight: '16px', mb: 3, }}>
                                    <Typography variant="bodyText3" color={theme.palette.secondary.main} sx={{ textDecoration: 'underline' }} >
                                        Total
                                    </Typography>
                                    <Typography variant="bodyText4" color={theme.palette.grey[600]}>
                                        ₹ {formatPrice(calculateTotal())}
                                    </Typography>
                                </Box>

                                {/* Proceed Button */}
                                <ContainedButton1 color="primary" sx={{ mt: 2, width: '100%' }} onClick={subscribe} disabled={checkedSubscriptions.length > 0 ? Boolean(false) : Boolean(true)} >
                                    <Typography variant="bodyText2">Proceed</Typography>
                                </ContainedButton1>
                            </Box>
                        </Grid>
                    </Grid>
                </StyledRoot>
            </Box>
        </>
    );
}