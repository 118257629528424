
const regex = {

    /**
     * It should only contain alpha numeric values.
     */
    nameRegex: "((^([a-zA-Z]+)$))",
    /**
    /**
     * It should only contain alpha numeric values and { }.
     */
    nameWithVariablesRegex: /^[A-Za-z0-9{}\s]+$/,
    /**
    /**
     * It should not contain any spaces.
     * It should have a single '@' symbol.
     * It should have at least one character before the '@' symbol.
     * It should have at least one character between the '@' symbol and the dot ('.').
     * It should have at least one character after the dot ('.').
     */
    emailRegex: /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/,
    /**
     * Indian Phone number start from 6,7,8,9
     * +91-9876543217
     * +919876543217
     * 09876543217
     */
    // phoneRegex: /^(?:(?:\+|0{0,1})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
    phoneRegex: /^[6789]\d{9}$/,

    /**
     * Standard GST Number format 
     */
    gstinRegex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
    /**
     * Standard PAN Number format 
     */
    panRegex: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,


    addressInputRegex: /^[\p{L}0-9\s\-,.]+$/u,

    zipRegex: "^[a-zA-Z0-9]{3,10}$",

    domainRegex: "^(?!://)([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$",

    alphaNumericRegex:"^(?!.*--)[^\\s-].*[^-\\s]$"

    

};

export default regex;
