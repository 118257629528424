import { Box, Grid, Typography } from '@mui/material';
import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup';
import DateInput from 'components/DateInput';
import { CustomAutocomplete } from 'components/DesignSystem/AutoComplete/CustomAutocomplete';
import { ContainedButton1, TextedButton1 } from 'components/DesignSystem/Button';
import InputField from 'components/DesignSystem/InputField';
import useSwalAlert from 'components/DesignSystem/SwalAlert/SwalAlert';
import { UploadPicture } from 'components/DesignSystem/UploadPicture';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { addAsset, fetchAssetById, updateAsset } from 'services/assetService';
import { fetchGenerics } from 'services/myPeopleService';
import { assetSubTypes } from 'utils/helper/AssetSubTypes';
import { assetTypes } from 'utils/helper/AssetTypes';
import { BankList } from 'utils/helper/BankList';
import * as Yup from 'yup';

const AddAsset = ({ assetCategory, editId, handleClose, fetchAssetsData, assets }) => {
    const showSwalAlert = useSwalAlert();
    const [type, setType] = useState();
    const [subType, setSubType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [formAction, setFormAction] = useState(null); // State for form action
    const [initialValues, setInitialValues] = useState({});
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
    const buttons = assetTypes[assetCategory] || [];
    const menuItems = assetSubTypes[type] || [];
    const selectedSubType = menuItems.find((item) => item.value === subType);
    const inputFields = selectedSubType?.inputFields || [];

    useEffect(() => {
        if (selectedSubType) {
            const newInitialValues = inputFields.reduce((acc, field) => {
                switch (field.type) {
                    case 'text':
                        acc[field.name] = ''; // Default value for text
                        break;
                    case 'number':
                        acc[field.name] = null; // Default value for number
                        break;
                    case 'file':
                        acc[field.name] = null; // Default value for file
                        break;
                    default:
                        acc[field.name] = ''; // Fallback for any other types
                        break;
                }
                return acc;
            }, {});

            setInitialValues(newInitialValues);


            // Construct validation schema dynamically
            const newValidationSchema = Yup.object().shape(
                inputFields.reduce((acc, field) => {
                    if (field.validation) {
                        acc[field.name] = field.validation; // Use provided validation
                    } else {
                        acc[field.name] = Yup.string().nullable(); // Default to nullable if no validation provided
                    }
                    return acc;
                }, {})
            );

            setValidationSchema(newValidationSchema);
            formik.setValues(newInitialValues); // Set form values
            // Reset errors and touched fields when subType changes
            formik.setErrors({});
            formik.setTouched({});
        }
    }, [subType, selectedSubType, type]);

    useEffect(() => {
        const fetchData = async () => {
            if (editId) {
                setIsLoading(true);
                try {
                    const { data } = await fetchAssetById(editId);
                    const asset = data.asset;
                    // Set type and subtype
                    setType(asset.type);       // Set the type
                    setSubType(asset.sub_type); // Set the subType

                    // Set form values with the asset information
                    const updatedValues = {
                        ...initialValues, // Start with the initial values
                        ...asset.information, // Merge with the fetched asset information
                    };

                    formik.setValues(updatedValues); // Update the form values with fetched asset data
                } catch (error) {
                    console.error("Error fetching asset:", error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [editId, initialValues]);

    // Mapping for asset categories to their respective properties
    const categoryMap = {
        real_estate: 'realEstate',
        financial: 'financial',
        personal_possessions: 'personalPossessions',
        other_assets: 'otherAssets',
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true); // Start loading
            // Check if the asset already exists
            const selectedCategory = categoryMap[assetCategory]; // Get the correct key for the current category
            const categoryAssets = assets[selectedCategory] || []; // Access assets dynamically

            const existingAsset = categoryAssets.some(asset => {
                return (
                    asset.id !== editId &&
                    asset.category === assetCategory &&
                    asset.type === type &&
                    asset.sub_type === subType &&
                    inputFields.every(field => asset.information[field.name]?.toLowerCase() === values[field.name]?.toLowerCase())
                );
            })

            if (existingAsset) {
                // Set Formik error for the fields where data is entered
                const errors = {};
                inputFields.forEach(field => {
                    if (values[field.name]) {
                        errors[field.name] = 'Already exists';
                    }
                });
                formik.setErrors(errors);
                setIsLoading(false); // Stop loading
                return; // Stop further execution
            }
            formik.setErrors({});
            // Construct payload based on form values
            const payload = {
                category: assetCategory, // This comes from props
                type, // The selected type from toggle buttons
                subType, // The selected subtype from dropdown
                asset: values,
            };
            try {
                if (editId) {
                    await updateAsset(editId, payload);
                    // Show SweetAlert2 notification and wait for it to close
                    handleClose();
                    await showSwalAlert({
                        title: 'Asset updated  successfully',
                        icon: 'success',
                        timer: 1500,
                    });
                } else {
                    await addAsset(payload);
                    // Reset form values and show the dialog based on button clicked
                    if (formAction === 'saveAndAddMore') {
                        formik.setValues(initialValues);
                        formik.setTouched({});
                        formik.setErrors({});
                        if (fetchAssetsData) {
                            await fetchAssetsData();;  // Fetch latest data
                        }
                    } else {
                        handleClose();
                    }
                    // Show SweetAlert2 notification and wait for it to close
                    await showSwalAlert({ title: 'New Asset added  successfully', icon: 'success', timer: 1500, });
                }
            } catch (error) {
                showSwalAlert({ title: 'Failed to add new Asset', icon: 'error', timer: 1500, });
            } finally {
                setIsLoading(false); // End loading
            }
        },
    });

    // Handle change in toggle button group
    const handleChange = (event, newValue) => {
        setType(newValue);
        setSubType(''); // Reset subType when type changes
    };

    const handleSave = () => {
        setFormAction('save');
        formik.handleSubmit();
        console.log("Values", formik.values, formik.errors)
    };

    const handleSaveAndAddMore = () => {
        setFormAction('saveAndAddMore');
        formik.handleSubmit();
    };

    const fetchBeneficiaries = async () => {
        try {
            const beneficiaryResponse = await fetchGenerics('beneficiary');
            const beneficiaries = beneficiaryResponse.data?.generics ?? [];
            setBeneficiaries(beneficiaries)
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchBeneficiaries();
    }, []);

    const filteredBene = useMemo(() => beneficiaries.filter(bene => bene.type === 'person'), [beneficiaries]);


    const nomineeANDJointOwnerOptions = useMemo(() => {
        return filteredBene.map(bene => ({
            value: bene.name,
            label: bene.name,
        }));
    }, [filteredBene]);

    const jointHolderNameOptions = useMemo(() => {
        return filteredBene.length > 0
            ? [{ value: 'None', label: 'None' }, ...filteredBene.map(bene => ({ value: bene.name, label: bene.name }))]
            : [{ value: 'None', label: 'None' }];
    }, [filteredBene]);


    return (
        <>
            <Grid item container md={12}>
                <CustomToggleButtonGroup buttons={buttons} value={type} onChange={handleChange} disabled={!!editId} />
            </Grid>

            <form>
                <Grid item container spacing={2} xs={12} md={6} mt={1}>
                    <Grid item xs={12} md={12}>
                        <CustomAutocomplete
                            name="subType"
                            options={menuItems}
                            placeholder="Select Type"
                            value={subType}
                            handleChange={(e) => {
                                setSubType(e.target.value);
                            }}
                            disabled={!!editId} // Disable when editing
                        />
                    </Grid>

                    {/* Render additional input fields based on selected subtype */}
                    {inputFields.map((field) => (
                        <Grid item xs={12} key={field.name}>
                            {field.type === 'file' ? (
                                <UploadPicture
                                    name={field.name}
                                    label={field.placeholder}
                                    accept="image/png,image/jpeg" // Accept only PNG and JPEG/JPG files
                                    maxFileSize={2097152} // 2MB size limit
                                    fileTypeText="Supports file up to 2MB"
                                    value={{
                                        add_picture: formik.values[field.name], // base64 string
                                        fileName: formik.values[`${field.name}_fileName`] // file name (from saved form data)
                                    }}
                                    onChange={(file) => {
                                        if (file) {
                                            // If a file is uploaded, set both base64 image and file name in the form field
                                            formik.setFieldValue(`${field.name}_fileName`, file.fileName);
                                            formik.setFieldValue(field.name, file.add_picture);
                                        } else {
                                            // If the file is null (file deleted), clear the form fields
                                            formik.setFieldValue(field.name, '');
                                            formik.setFieldValue(`${field.name}_fileName`, '');
                                        }
                                    }}
                                    error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                                    helperText={formik.touched[field.name] && formik.errors[field.name]}
                                />

                            ) : field.type === 'date' ? (
                                <DateInput
                                    name={field.name}
                                    value={formik.values[field.name]}
                                    label={field.placeholder}
                                    onChange={(date) => formik.setFieldValue([field.name], date)}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                                    helperText={formik.touched[field.name] && formik.errors[field.name]}
                                />
                            ) : field.type === 'number' ? (
                                <InputField
                                    name={field.name}
                                    type="number" // Keep type as text to allow for decimal input
                                    value={formik.values[field.name] !== null ? formik.values[field.name] : ''} // Display empty string if null
                                    placeholder={field.placeholder}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        // Check if the value is empty
                                        if (value === '') {
                                            formik.setFieldValue(field.name, null); // Set to null if empty
                                            return;
                                        }
                                        // Allow only numbers and one decimal point
                                        const isValidNumber = /^-?\d*\.?\d*$/.test(value);
                                        if (isValidNumber) {
                                            // If the user enters '0', set it as '0' string
                                            if (value === '0') {
                                                formik.setFieldValue(field.name, '0');
                                            } else {
                                                // Try to convert the value to a number
                                                const numericValue = Number(value);
                                                formik.setFieldValue(field.name, numericValue); // Store as number
                                            }
                                        } else {
                                            // Keep as text if invalid
                                            formik.setFieldValue(field.name, value);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                                    helperText={formik.touched[field.name] && formik.errors[field.name]}
                                    fullWidth
                                    multiline={field.multiline}
                                    rows={field.rows}
                                />
                            ) : field.type === 'dropDown' ? (
                                <CustomAutocomplete
                                    name={field.name}
                                    type={field.type}
                                    value={formik.values[field.name]}
                                    placeholder={field.placeholder}
                                    handleChange={formik.handleChange}
                                    // options={field.options}
                                    options={{
                                        bank_name: BankList,
                                        joint_owner_name: nomineeANDJointOwnerOptions,
                                        nominee: nomineeANDJointOwnerOptions,
                                        joint_holder_name: jointHolderNameOptions,
                                        join_account_holder:jointHolderNameOptions,
                                    }[field.name] || field.options}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                                    helperText={formik.touched[field.name] && formik.errors[field.name]}
                                    fullWidth
                                />
                            ) : (
                                <InputField
                                    name={field.name}
                                    type={field.type}
                                    value={formik.values[field.name]}
                                    placeholder={field.placeholder}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                                    helperText={formik.touched[field.name] && formik.errors[field.name]}
                                    fullWidth
                                    multiline={field.multiline}
                                    rows={field.rows}
                                />
                            )}
                        </Grid>
                    ))}
                </Grid>
            </form>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 25,
                    right: 20,
                    display: 'flex',
                    gap: 1,
                }}
            >
                {!editId && (<TextedButton1
                    sx={{ mr: 2, minWidth: '125px' }}
                    onClick={handleSaveAndAddMore}
                    disabled={isLoading || !subType} // Disable if loading
                >
                    <Typography variant="bodyText3">Save & Add More</Typography>
                </TextedButton1>)}

                <ContainedButton1
                    sx={{ mr: 2, minWidth: '125px' }}
                    onClick={handleSave}
                    disabled={isLoading || !subType} // Disable if loading
                >
                    <Typography variant="bodyText3"> {editId ? 'Update' : 'Save'}</Typography>
                </ContainedButton1>
            </Box>
        </>
    );
};
export default AddAsset;
