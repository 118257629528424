import axios from "axios";
import dayjs from 'dayjs';
import { filter } from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

// @mui
import {
    Box,
    Button,
    Card,
    Checkbox,
    Chip,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Slider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Swal from "sweetalert2";
import CenterModal from "../components/CenterModal";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
import { UserListHead, UserListToolbar } from "../components/user";
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    // { id: "edit-action" },
    { id: "name", label: "Name", alignRight: false },
    { id: "description", label: "Description", alignRight: false },
    { id: "type", label: "Type", alignRight: false },
    { id: "couponCodePrefix", label: "Code Prefix", alignRight: false },
    { id: "createdAt", label: "Created At", alignRight: false },
    { id: "startDate", label: "Start Date", alignRight: false },
    { id: "endDate", label: "End Date", alignRight: false },
    { id: "discount", label: "%Discount", alignRight: false },
    { id: "applicableServices", label: "Service(s) Applicable", alignRight: false },
    { id: "generatedCoupons", label: "Coupon Generated", alignRight: false, alignCenter: true },
    { id: "consumedCoupons", label: "Coupon Consumed", alignRight: false, alignCenter: true },
    { id: "balanceCoupons", label: "Coupon Balance", alignRight: false, alignCenter: true },
    { id: "status", label: "Status", alignRight: false },
    { id: "action" }
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => JSON.stringify(_user ?? {}).toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function CouponsPage() {
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState("asc");

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState("name");

    const [filterName, setFilterName] = useState("");

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [coupons, setCoupons] = useState([]);
    const [beneficiaries, setBeneficiaries] = useState([]);

    const [schemas, setSchemas] = useState({});

    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [assetData, setAssetData] = useState({});
    const [errors, setErrors] = useState({});
    const [type, setType] = useState("");
    const [subType, setSubType] = useState("");
    const [reorderedArray, setReorderedArray] = useState("");
    const [subscriptions, setSubscriptions] = useState([]);

    const [applicableServices, setApplicableServices] = useState([]);

    useEffect(() => {
        axios.get("/subscriptions").then(res => {
            setSubscriptions(res.data?.subscriptions ?? []);
        });
    }, []);



    const fetchAssets = () => {
        axios.get("/coupons").then(response => {
            console.log('coupons', response);

            const fetchedCoupons = response.data.coupons;
            // Sort the array by "status" and "endDate"
            if (fetchedCoupons.length > 0) {

                fetchedCoupons.sort((a, b) => {
                    // Sort by "status" (Active first)
                    if (a.status === "Active" && b.status !== "Active") {
                        return -1;
                    }
                    if (a.status !== "Active" && b.status === "Active") {
                        return 1;
                    }

                    // Sort by "endDate" (nearest to the current date)
                    const endDateA = new Date(a.endDate);
                    const endDateB = new Date(b.endDate);
                    const timeDiffA = Math.abs(endDateA - new Date());
                    const timeDiffB = Math.abs(endDateB - new Date());

                    return timeDiffA - timeDiffB;
                });
            }
            // Custom sorting function
            // const customSort = (a, b) => {
            //     if (a.type === "other_assets" && b.type !== "other_assets") {
            //         return 1; // Move a to the end
            //     }
            //     if (a.type !== "other_assets" && b.type === "other_assets") {
            //         return -1; // Move b to the end
            //     }
            //     return 0; // Maintain the order
            // };

            // // Sort the array using the custom sorting function
            // const sortedArray = response.data?.assets.slice().sort(customSort);
            setCoupons(fetchedCoupons);
        }).catch(err => console.error(err));
    };

    const addNewCoupon = () => {
        axios.post("/coupons", { couponName, couponDescription, couponCodePrefix, startWithNumber, noOfCoupons, startDate, endDate, discount, couponType, applicableServices }).then(response => {
            // toastr.success("Coupon saved successfully");
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Coupon saved successfully",
                showConfirmButton: false,
                timer: 3000
            });
            fetchAssets();
            setAddNew(false);
            setEdit(false);
            resetForm();
        }).catch(err => {
            // toastr.error("Failed to add coupon");
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Failed to add coupon",
                showConfirmButton: false,
                timer: 3000
            });
        });
    };

    const editAsset = () => {
        // axios.post(`/properties/${editingId}`, { type, subType, asset: assetData }).then(response => {
        //     toastr.success("Asset updated successfully");
        //     fetchAssets();
        //     setEdit(false);
        //     setOpen(false);
        //     setEditingId(null);
        // }).catch(err => toastr.error("Failed to update asset"));
    };

    const deleteAsset = (id) => {
        // axios.delete(`/properties/${id}`).then(response => {
        //     toastr.success("Asset deleted successfully");
        //     fetchAssets();
        //     setAssetData({});
        // }).catch(err => toastr.error("Failed to update asset"));
    };

    useEffect(() => {
        fetchAssets();
    }, []);

    const handleOpenMenu = (event, row) => {
        setOpen(event.currentTarget);
        setType(row.type);
        setSubType(row.sub_type);
        setAssetData(row.information);
        setEditingId(row.id);
    };

    const handleCloseMenu = () => {
        setOpen(null);
        setAssetData({});
        setType("");
        setSubType("");
        setEditingId(null);
        setEdit(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = coupons.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - coupons.length) : 0;

    const filteredCoupons = applySortFilter(coupons, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredCoupons.length && !!filterName;

    const steps = [
        {
            target: "#new-generic",
            content: `Click to add new asset`
        },
        {
            target: "body",
            content: `Fill in the data for asset`
        },
        {
            target: "#new-generic-button",
            content: "Click the save button"
        }
    ];
    const handleJoyrideCallback = (data) => {
        const { index, action, size } = data;
        if (action === "next" && index === 0) {
            setAddNew(true);
        } else if (action === "next" && index === size - 1) {
            setAddNew(false);
        }
    };


    //   useEffect(() => {
    //     const handleEnter = (e) => {
    //       if (e.code?.toLowerCase() === "enter" && !e.repeat) {
    //         e.preventDefault();
    //         if (errors?.length === 0) {
    //           if (editingId) editAsset();
    //           else addNewAsset();
    //         }
    //       }
    //     };
    //     window.addEventListener("keyup", handleEnter);
    //     return () => {
    //       window.removeEventListener("keyup", handleEnter);
    //     };
    //   }, [editingId, errors]);

    // const { isLoading, setShowHelp, editProfile } = useContext(LoadingContext);

    // useEffect(() => {
    //     if (!sessionStorage.getItem(`video_close_assets`) && !isLoading && coupons.length === 0) {
    //         setShowHelp(true);
    //     } else {
    //         setShowHelp(false);
    //     }
    // }, [isLoading, isLoading, coupons]);

    const [couponName, setCouponName] = useState('');
    const [couponDescription, setCouponDescription] = useState('');
    const [couponCodePrefix, setCouponCodePrefix] = useState('');
    const [startWithNumber, setStartWithNumber] = useState('');
    const [noOfCoupons, setNoOfCoupons] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [discount, setDiscount] = useState('');
    const [couponType, setCouponType] = useState('');
    const [applicableServicesError, setApplicableServicesError] = useState(null);
    const [couponCodePrefixError, setCouponCodePrefixError] = useState(null);
    const [couponNameError, setCouponNameError] = useState(null);
    const [startDateError, setStartDateError] = useState(null);
    const [endDateError, setEndDateError] = useState(null);

    const isValidDate = (dateString) => {
        // Check if the input is not empty
        if (!dateString) return false;

        // Attempt to create a Date object from the input
        const date = new Date(dateString);
        // console.log('dob is', !Number.isNaN(date.getTime()));
        // console.log('dob instance', date instanceof Date);
        // Check if the created date is a valid date
        return !Number.isNaN(date.getTime()) && date instanceof Date;
    }

    const handleStartDateChange = (date) => {
        // setStartDate(date);
        const regex = /^\d{2}-\d{2}-(20\d{2}|21\d{2})$/;
        console.log('regexvalid', dayjs(date).format('DD-MM-YYYY'), regex.test(dayjs(date).format('DD-MM-YYYY')));
        if (regex.test(dayjs(date).format('DD-MM-YYYY'))) {
            console.log('start date valid', date, new Date(), date <= new Date());
            if (isValidDate(date)) {
                if (date < new Date().setHours(0, 0, 0, 0)) {
                    console.log('Start date cannot be a past dates');
                    setStartDateError('Start date cannot be a past dates');
                }
                else if (endDate && date > endDate) {
                    // Validate that end date is not less than start date
                    setStartDateError('Start date cannot be greater than end date');
                } else {
                    setStartDateError(null);
                }
                setStartDate(date);

                if (date && endDate < date) {
                    // Validate that end date is not less than start date
                    setEndDateError('End date cannot be less than start date');
                } else if (endDate < new Date().setHours(23, 49, 0, 0)) {
                    // Validate that end date is not a past date
                    setEndDateError('End date cannot be a past dates');
                } else {
                    setEndDateError(null);
                }
            }
        }
        else {
            setStartDateError('Please enter a valid date (DD-MM-YYYY) with hyphen')
        }
    };

    const handleEndDateChange = (date) => {
        const regex = /^\d{2}-\d{2}-(20\d{2}|21\d{2})$/;
        console.log('regexvalid', dayjs(date).format('DD-MM-YYYY'), regex.test(dayjs(date).format('DD-MM-YYYY')));
        if (regex.test(dayjs(date).format('DD-MM-YYYY'))) {
            if (isValidDate(date)) {
                if (date && startDate > date) {
                    console.log('start date cannot be greater than end date', date, startDate, startDate > date);
                    // Validate that end date is not less than start date
                    setStartDateError('Start date cannot be greater than end date');
                }
                else {
                    console.log('startDateError');
                    setStartDateError(null);
                }

                if (startDate && date < startDate) {
                    // Validate that end date is not less than start date
                    setEndDateError('End date cannot be less than start date');
                } else if (date < new Date().setHours(0, 0, 0, 0)) {
                    console.log('enddate', date, new Date(), date < new Date().setHours(0, 0, 0, 0));
                    // Validate that end date is not a past date
                    setEndDateError('End date cannot be a past date');
                } else {
                    setEndDateError(null);
                }
                setEndDate(date);
            }
        }
        else {
            setEndDateError('Please enter a valid date (DD-MM-YYYY) with hyphen')
        }
    };

    const handleCouponNameChange = (event) => {
        const couponNames = (coupons ?? []).map(b => b.name ?? "").filter(x => !!x);
        console.log("couponNames", couponNames);
        if (couponNames.includes(event.target.value)) {
            setCouponNameError(`${event.target.value} coupon name already exists`);
        }
        else {
            setCouponNameError(null);
        }
        setCouponName(event.target.value);
    }
    const handleCouponCodePrefixChange = (event) => {
        const couponCodePrefixs = (coupons ?? []).map(b => b.couponCodePrefix?.toUpperCase() ?? "").filter(x => !!x);
        console.log("couponCodePrefixs", couponCodePrefixs);
        if (couponCodePrefixs.includes(event.target.value)) {
            setCouponCodePrefixError(`${event.target.value} coupon code prefix already exists`);
        }
        else {
            setCouponCodePrefixError(null);
        }
        setCouponCodePrefix(event.target.value.toUpperCase());
    }
    const handleChange = (event) => {
        const { value, checked } = event.target;
        console.log('value', value);
        console.log('checked', checked);
        if (checked) {
            setApplicableServices([...applicableServices, +value]);
        } else {
            setApplicableServices(applicableServices.filter((id) => id !== +value));
        }
    };

    const resetForm = () => {

        setCouponName('');
        setCouponDescription('');
        setCouponCodePrefix('');
        setStartWithNumber('');
        setNoOfCoupons('');
        setStartDate(null);
        setEndDate(null);
        setDiscount('');
        setCouponType('');
        setApplicableServices([]);
        setApplicableServicesError(null);
        setStartDateError(null);
        setEndDateError(null);
    };
    const isAnyCheckboxSelected = applicableServices.length === 0;
    return (
        <>
            <Helmet>
                <title> Coupons | Get Will Done </title>
            </Helmet>

            <Container fullWidth style={{ maxWidth: "unset" }}>
                {/* <Joyride callback={handleJoyrideCallback} run={assets.length === 0} continuous showProgress showSkipButton
                 steps={steps}
                 styles={{ options: { zIndex: 10000 } }} /> */}
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom>
                        Coupons
                    </Typography>
                    <div style={{ flexGrow: 1 }}>
                        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    </div>
                    <Tooltip title={`New Coupon`}>
                        <Button id={"new-generic"} onClick={() => {
                            handleCloseMenu(); setAddNew(true)
                        }} variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}>
                            New Coupon
                        </Button>
                    </Tooltip>
                </Stack>
                <CenterModal
                    open={addNew}
                    title={"New Coupon"}
                    onClose={() => {
                        setAddNew(false);
                        setEdit(false);
                        setType("");
                        setSubType("");
                        setAssetData({});
                        resetForm();
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ m: 0, p: 2, pb: 0, bgcolor: "white" }}>
                        <Stack direction={"row"} spacing={1} alignItems="center">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormControl sx={{ minWidth: 200, mb: 1, mt: 1 }}>
                                                <InputLabel id="coupon-type-select-label">Coupon Type</InputLabel>
                                                <Select
                                                    labelId="coupon-type-select-label"
                                                    label="Coupon Type"
                                                    value={couponType}
                                                    onChange={(e) => setCouponType(e.target.value)}
                                                >
                                                    <MenuItem value="auto">Auto Generation</MenuItem>
                                                    <MenuItem value="sequence">Sequential Generation</MenuItem>
                                                    <MenuItem value="date">Date-Based Coupon</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl
                                                required
                                                error={isAnyCheckboxSelected}
                                                component="fieldset"
                                                sx={{ mb: 1, mt: 1 }}
                                                variant="standard"
                                            >
                                                <FormLabel component="legend">Applicable Services</FormLabel>
                                                <FormGroup>
                                                    {subscriptions && subscriptions.filter(subscription => [1, 2, 5].includes(subscription.id)).map((subscription) =>
                                                        <>
                                                            <FormControlLabel
                                                                key={subscription.id}
                                                                control={
                                                                    <Checkbox checked={applicableServices.includes(subscription.id)} onChange={handleChange} value={subscription.id} />
                                                                }
                                                                label={`${subscription.name}`}
                                                            />
                                                        </>
                                                    )}
                                                </FormGroup>
                                                {isAnyCheckboxSelected && (
                                                    <FormHelperText color='error'>Please select at least one service.</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <FormControl
                                                required
                                                error={couponNameError}
                                                fullWidth
                                                variant="standard"
                                            >
                                                <TextField
                                                    label="Coupon Name"
                                                    fullWidth
                                                    error={couponNameError}
                                                    value={couponName}
                                                    onChange={handleCouponNameChange}
                                                />
                                                {couponNameError && (
                                                    <FormHelperText color='error'>{couponNameError}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl
                                                required
                                                fullWidth
                                                variant="standard"
                                            >
                                                <TextField
                                                    label="Coupon Description"
                                                    fullWidth
                                                    value={couponDescription}
                                                    onChange={(e) => setCouponDescription(e.target.value)}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl
                                                required
                                                error={couponCodePrefixError}
                                                fullWidth
                                                variant="standard"
                                            >
                                                <TextField
                                                    label="Coupon Code Prefix"
                                                    fullWidth
                                                    id="couponCodePrefix-input"
                                                    error={couponCodePrefixError}
                                                    value={couponCodePrefix}
                                                    onChange={handleCouponCodePrefixChange}
                                                />
                                                {couponCodePrefixError && (
                                                    <FormHelperText color='error'>{couponCodePrefixError}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            {/* <TextField
                                        fullWidth
                                        label="Discount%"
                                        type="number"
                                        inputProps={{
                                            step: 0.5, // To allow floating-point numbers
                                            min: 1,    // Minimum value
                                            max: 100,  // Maximum value
                                        }}
                                        value={discount}
                                        onChange={(e) => setDiscount(e.target.value)}
                                    /> */}
                                            <Typography id="slider-label">Discount: {discount}%</Typography>
                                            <Slider
                                                value={discount}
                                                onChange={(e) => setDiscount(e.target.value)}
                                                valueLabelDisplay="auto"
                                                valueLabelFormat={(value) => `${value}%`}
                                                step={0.5}
                                                min={0.5}
                                                max={99.5}
                                                aria-labelledby="slider-label"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl
                                                required
                                                error={startDateError}
                                                fullWidth
                                                variant="standard"
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        label={`Start Date`}
                                                        value={startDate}
                                                        error={startDateError}
                                                        fullWidth
                                                        inputFormat={"dd-MM-yyyy"}
                                                        minDate={new Date()}
                                                        minDateMessage="Past dates are not allowed"
                                                        onChange={handleStartDateChange}
                                                        renderInput={(params) => <TextField fullWidth error={startDateError} {...params} placeholder={"DD-MM-YYYY"}
                                                        // helperText={!dob ? "Please select your Date of Birth" : age < 18 ? "You should be older than 18" : ""}
                                                        />}
                                                    />
                                                    {startDateError && (
                                                        <FormHelperText color='error'>{startDateError}</FormHelperText>
                                                    )}
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl
                                                required
                                                error={endDateError}
                                                fullWidth
                                                variant="standard"
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        label="End Date (Expiry Date)"
                                                        value={endDate}
                                                        error={endDateError}
                                                        inputFormat={"dd-MM-yyyy"}
                                                        minDate={startDate ?? new Date()}
                                                        minDateMessage="Past dates are not allowed"
                                                        onChange={handleEndDateChange}
                                                        renderInput={(params) => <TextField InputProps={{ readOnly: true }} fullWidth {...params} placeholder={"DD-MM-YYYY"}
                                                        />}
                                                    />
                                                    {endDateError && (
                                                        <FormHelperText color='error'>{endDateError}</FormHelperText>
                                                    )}
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ mt: 2 }}>
                                        <Grid item xs={12} md={4}>
                                            {["auto", "sequence"].includes(couponType) && (

                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    label="No of Coupons"
                                                    value={noOfCoupons}
                                                    inputProps={{
                                                        step: 1, // To allow floating-point numbers
                                                        min: 1,    // Minimum value
                                                        max: 1000,  // Maximum value
                                                    }}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        if (value !== "") {
                                                            value = parseInt(value, 10);
                                                        }
                                                        if (value !== "" && value < 1) {
                                                            value = 1;
                                                        } else if (value > 1000) {
                                                            value = 1000;
                                                        }
                                                        setNoOfCoupons(value)
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            {couponType === "sequence" && (
                                                <TextField
                                                    type="number"
                                                    fullWidth
                                                    label="Start with Number"
                                                    value={startWithNumber}
                                                    inputProps={{
                                                        step: 1, // To allow floating-point numbers
                                                        min: 1,    // Minimum value
                                                        // max: 1000,  // Maximum value
                                                    }}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        console.log('value', value);
                                                        if (value !== "" && value < 1) {
                                                            value = 1;
                                                        }
                                                        setStartWithNumber(value);
                                                    }
                                                    }
                                                />
                                            )}
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>



                        </Stack>
                    </Box>

                    <Stack sx={{ bgcolor: '#BDBDBD', m: -1, mb: 0, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => {
                            setAddNew(false);
                            setEdit(false);
                            resetForm();
                        }}>Cancel</Button>
                        <Button id={"new-generic-button"} disabled={isAnyCheckboxSelected || couponName === "" || couponCodePrefix === "" || couponNameError !== null || couponCodePrefixError !== null || startDate === null || endDate === null || startDateError !== null || endDateError !== null || discount === "" || couponType === "" || (couponType === "auto" && noOfCoupons === "") || (couponType === "sequence" && (noOfCoupons === "" || startWithNumber === ""))}
                            onClick={() => {
                                addNewCoupon();
                            }}
                            variant="contained" startIcon={<Iconify icon="eva:save-fill" />}>
                            Save
                        </Button>
                    </Stack>
                </CenterModal>

                <Card>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={coupons.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredCoupons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const { id, type, name, description, discount, applicableServices, couponCodePrefix, startWithNumber, consumedCoupons, generatedCoupons, createdAt, startDate, endDate, noOfCoupons, status } = row;
                                        const selectedUser = selected.indexOf(id) !== -1;
                                        const serviceNames = subscriptions.filter(subscription => applicableServices.includes(subscription.id)).map(sub => sub.name);
                                        return (<>
                                            <TableRow
                                                sx={{ bgcolor: (theme) => editingId === id ? "rgba(255, 156, 65, 0.18)" : "initial" }}
                                                key={id} tabIndex={-1}>
                                                {/* <TableCell align="right">
                                                    <IconButton onClick={(e) => {
                                                        if (editingId === id) {
                                                            handleCloseMenu();
                                                        } else {
                                                            handleOpenMenu(e, row);
                                                        }
                                                    }}><Iconify icon={editingId === id ? "uil:angle-down" : "uil:angle-right"} /></IconButton>
                                                </TableCell> */}

                                                <TableCell >{name}</TableCell>
                                                <TableCell >{description}</TableCell>
                                                <TableCell >{type}</TableCell>
                                                <TableCell >{couponCodePrefix}</TableCell>
                                                <TableCell >{dayjs(createdAt).format('DD-MM-YYYY')}</TableCell>
                                                <TableCell >{dayjs(startDate).format('DD-MM-YYYY')}</TableCell>
                                                <TableCell >{dayjs(endDate).format('DD-MM-YYYY')}</TableCell>
                                                <TableCell >{`${discount}%` ?? ""}</TableCell>
                                                <TableCell >{subscriptions.filter(subscription => applicableServices.includes(subscription.id)).map(sub => <Chip size="small" sx={{ marginBottom: 1, marginRight: 1 }} key={sub.id} label={`${sub.name}`} variant="outlined" />)}</TableCell>
                                                <TableCell >
                                                    <Button
                                                        // onClick={handleMicrosoftLogin}
                                                        fullWidth
                                                        sx={{ paddingTop: '7px', paddingBottom: '7px' }}
                                                        // variant="outlined"
                                                        // color="primary"
                                                        component="a"
                                                        // startIcon={<img src={'/assets/Sign-In-Apple.png'} height={'32px'} alt="Sign In with Apple" />}
                                                        href={`${process.env.REACT_APP_API_URL}/coupons/generate-pdf/${id}-generated`}
                                                        target="_blank"
                                                    >
                                                        <Chip sx={{ cursor: 'pointer' }} label={`${generatedCoupons.length}`} variant="outlined" />
                                                    </Button>
                                                </TableCell>
                                                <TableCell >
                                                    <Button
                                                        // onClick={handleMicrosoftLogin}
                                                        fullWidth
                                                        sx={{ paddingTop: '7px', paddingBottom: '7px' }}
                                                        // variant="outlined"
                                                        // color="primary"
                                                        component="a"
                                                        // startIcon={<img src={'/assets/Sign-In-Apple.png'} height={'32px'} alt="Sign In with Apple" />}
                                                        href={`${process.env.REACT_APP_API_URL}/coupons/generate-pdf/${id}-consumed`}
                                                        target="_blank"
                                                    >
                                                        <Chip sx={{ cursor: 'pointer' }} label={`${consumedCoupons.length}`} variant="outlined" />
                                                    </Button>
                                                </TableCell>
                                                <TableCell align="center">{generatedCoupons.length - consumedCoupons.length}</TableCell>
                                                <TableCell >{status}</TableCell>


                                                {/* <TableCell align="left">{information?.nominee ?? "-"}</TableCell> */}

                                                {/* <TableCell>
                                                    {<>
                                                        <ButtonGroup>
                                                            <Tooltip title={`Edit Asset`}>
                                                                <IconButton size="small" color="primary" onClick={async (e) => {
                                                                    handleOpenMenu(e, row);
                                                                    setEdit(true);
                                                                }}>
                                                                    <Iconify icon={"fluent:edit-32-regular"} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={`Delete Asset`}>
                                                                <IconButton size="small" color="primary" onClick={async (e) => {
                                                                    Swal.fire({
                                                                        title: "Are you sure?",
                                                                        text: (`Do you want to delete the asset?`),
                                                                        icon: "warning",
                                                                        showCancelButton: true,
                                                                        allowEnterKey: false,
                                                                        allowOutsideClick: false,
                                                                        confirmButtonColor: "#3085d6",
                                                                        cancelButtonColor: "#d33",
                                                                        confirmButtonText: "Yes"
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            deleteAsset(id);
                                                                        }
                                                                    });
                                                                }}>
                                                                    <Iconify icon={"fluent:delete-32-regular"} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ButtonGroup>
                                                    </>}
                                                </TableCell> */}
                                            </TableRow>

                                        </>);
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25,50,100]}
                        component="div"
                        count={coupons.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

        </>
    );
}
