import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Divider, IconButton, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Icon from '../Icon';

const BackButtonWithDivider = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', padding: '16px 0', width: '100%' }}>
            <IconButton onClick={handleBackClick} sx={{ background: theme.palette.grey[200] }}>
                <Icon name='back-arrow-icon.png' width={6.5} height={9}/>
            </IconButton>
            <Divider sx={{ flexGrow: 1, marginLeft: '8px', borderBottomWidth: '1px', borderColor: theme.palette.grey[100] }} />
        </div>
    );
};

export default BackButtonWithDivider;
