import { Box, IconButton, Typography, useTheme } from '@mui/material';
import Icon from 'components/DesignSystem/Icon';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PageContainer from 'views/dashboard/PageContainer';
import DocumentPreview from './DocumentPreview';
import MyWillModal from './MyWillModal';
import WillGenerationChecklist from './WillGenerationChecklist';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const MyWill = () => {
    const theme = useTheme();
    const [html, setHtml] = useState('');
    const [openFullScreen, setOpenFullScreen] = useState(false);

    const handleOpenFullScreenModal = () => setOpenFullScreen(true);
    const handleCloseFullScreenModal = () => setOpenFullScreen(false);

    return (
        <>
            <Helmet>
                <title> My Will | Get Will Done </title>
            </Helmet>
            <PageContainer>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="title1">My Will</Typography>
                    <Typography variant="bodyText3" color={theme.palette.grey[400]} display={'block'} width={'70%'}>
                        This preview shows you how all your details will appear in the final Will. Use the Checklist as a guide of
                        progress towards readying and Generating your Will.
                    </Typography>
                    <Box sx={{ display: 'flex', height: '100%' }}>
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '660px',
                                    border: '1px solid #C5C5C5',
                                    borderRadius: '20px',
                                    overflowX: 'auto',
                                }}
                            >
                                <DocumentPreview documentHtml={html} />
                            </Box>
                            <Box sx={{ textAlign: 'end' }}>
                                <IconButton
                                    style={{
                                        color: '#fff',
                                        background: '#fff',
                                    }}
                                    onClick={handleOpenFullScreenModal}
                                >
                                    <Icon name="fullscreen-icon.png" size={21} />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <WillGenerationChecklist setHtml={setHtml} />
                        </Box>
                    </Box>
                </Box>
                <MyWillModal open={openFullScreen} handleClose={handleCloseFullScreenModal} documentHtml={html} />
            </PageContainer>
        </>
    );
};

export default MyWill;
