import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import AuthRoutes from 'helpers/auth';
// import LoginPage from "./pages/LoginPage";

// login option 3 routing
const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
const ForgotPasswordPage = Loadable(lazy(() => import('../pages/ForgotPasswordPage')));
const VerifyOtpPage = Loadable(lazy(() => import('../pages/VerifyOtpPage')));
const VerifyRegistrationOtpForm = Loadable(lazy(() => import('../pages/VerifyRegistrationOtpPage')));
const PasswordSuccessPage = Loadable(lazy(() => import('../pages/PasswordSuccessPage')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <AuthRoutes />,
  children: [
    {
      path: '/',
      element: <MinimalLayout />,
      children: [
        {
          index: true,
          element: <LoginPage />,
        },
        {
          path: '/login',
          element: <LoginPage />,
        },
        {
          path: '/forgot-password',
          element: <ForgotPasswordPage />,
        },
        {
          path: '/verify-otp',
          element: <VerifyOtpPage />,
        },
        {
          path: '/verifyRegistrationOtp',
          element: <VerifyRegistrationOtpForm />,
        },
        {
          path: '/password-success',
          element: <PasswordSuccessPage />,
        },
      ],
    },
  ],
};

export default AuthenticationRoutes;
