import { lazy } from 'react';

// project imports
import ProtectedRoutes from 'helpers/ProtectedRoutes';
import MainLayout from 'layout/MainLayout';
import AdminAnalyticalDashboard from 'oldLayouts/pages/AdminAnalyticalDashboard';
import CommissionDetailsPage from 'oldLayouts/pages/AdminDashboardPages/CommissionDetailsPage';
import CompletedWillsPage from 'oldLayouts/pages/AdminDashboardPages/CompletedWillsPage';
import ConversionRatioPage from 'oldLayouts/pages/AdminDashboardPages/ConversionRatioPage';
import GstPaidDetailsPage from 'oldLayouts/pages/AdminDashboardPages/GstPaidDetailsPage';
import IncompleteWillsPage from 'oldLayouts/pages/AdminDashboardPages/IncompleteWillsPage';
import NumberOfClientsPage from 'oldLayouts/pages/AdminDashboardPages/NumberOfClientsPage';
import RepeatRatePage from 'oldLayouts/pages/AdminDashboardPages/RepeatRatePage';
import TopPerformerPage from 'oldLayouts/pages/AdminDashboardPages/TopPerformerPage';
import CouponsPage from 'oldLayouts/pages/CouponsPage';
import PartnersPage from 'oldLayouts/pages/PartnersPage';
import ReportsPage from 'oldLayouts/pages/Reports';
import LandingPage from 'pages/LandingPage/LandingPage';
import ResourceHub from 'pages/LandingPage/ResourceHub';
import JourneyPage from 'pages/OnboardingFlow/JourneyPage';
import WelcomePage from 'pages/OnboardingFlow/WelcomePage';
import AddressDetailsPage from 'pages/ProfileProcessPages/AddressDetailsPage';
import ExistingWillDetailsPage from 'pages/ProfileProcessPages/ExistingWillDetailsPage';
import PersonalDetailsPage from 'pages/ProfileProcessPages/PersonalDetailsPage';
import ProfileCompletionPage from 'pages/ProfileProcessPages/ProfileCompletionPage';
import ServicesPage from 'pages/ServicePage/ServicesPage';
import { Outlet } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import AssetPage from 'views/AssetPage';
import DistributionPage from 'views/distribution';
import MyWill from 'views/myWill';
import WillGenerationSuccess from 'views/myWill/WillGenerationSuccess';
import ProfilePage from 'views/profile';
import DashboardLayout from '../oldLayouts/dashboard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <ProtectedRoutes />,
  children: [
    {
      path: '/',
      element: <Outlet />,
      children: [
        {
          path: '/personalDetails',
          element: <PersonalDetailsPage />,
        },
        {
          path: '/addressDetails',
          element: <AddressDetailsPage />,
        },
        {
          path: '/existingDetails',
          element: <ExistingWillDetailsPage />,
        },
        {
          path: '/profileCompletion',
          element: <ProfileCompletionPage />,
        },
        {
          path: '/welcome',
          element: <WelcomePage />,
        },
        {
          path: '/journey',
          element: <JourneyPage />,
        },
        {
          path: '/Services',
          element: <ServicesPage />,
        },
        {
          path: '/landingPage',
          element: <LandingPage />,
        },
        {
          path: '/resourceHub',
          element: <ResourceHub />,
        },
        {
          path: '/willGenerationSuccess',
          element: <WillGenerationSuccess />,
        },
        {
          path: '/dashboard',
          element: <MainLayout />,
          children: [
            {
              path: '/dashboard/default',
              element: <DashboardDefault />,
            },
            {
              path: '/dashboard/asset',
              element: <AssetPage />,
            },
            {
              path: '/dashboard/distribution',
              element: <DistributionPage />,
            },
            {
              path: '/dashboard/profilePage',
              element: <ProfilePage />,
            },
            {
              path: '/dashboard/myWill',
              element: <MyWill />,
            },
          ],
        },
        {
          path: '/admin',
          element: <DashboardLayout />,
          children: [
            {
              path: '/admin/analyticaldashboard',
              element: <AdminAnalyticalDashboard />,
            },
            {
              path: '/admin/coupons',
              element: <CouponsPage />,
            },
            {
              path: '/admin/partners',
              element: <PartnersPage />,
            },
            {
              path: '/admin/reports',
              element: <ReportsPage />,
            },
            {
              path: '/admin/no-of-clients',
              element: <NumberOfClientsPage />,
            },
            {
              path: '/admin/top-performer-page',
              element: <TopPerformerPage />,
            },
            {
              path: '/admin/conversion-ratio-page',
              element: <ConversionRatioPage />,
            },
            {
              path: '/admin/repeat-rate-page',
              element: <RepeatRatePage />,
            },
            {
              path: '/admin/commission-details-page',
              element: <CommissionDetailsPage />,
            },
            { path: '/admin/partner-details-page', element: <PartnersPage /> },
            { path: '/admin/gst-details-page', element: <GstPaidDetailsPage /> },
            { path: '/admin/complete-wills-page', element: <CompletedWillsPage /> },
            { path: '/admin/incomplete-wills-page', element: <IncompleteWillsPage /> },
          ],
        },
      ],
    },
  ],
};

export default MainRoutes;
