// import PropTypes from 'prop-types';
// import { forwardRef, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Link, useLocation } from 'react-router-dom';

// // material-ui
// import { useTheme } from '@mui/material/styles';
// import Avatar from '@mui/material/Avatar';
// import Chip from '@mui/material/Chip';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import Typography from '@mui/material/Typography';
// import useMediaQuery from '@mui/material/useMediaQuery';

// // project imports
// import { MENU_OPEN, SET_MENU } from 'store/actions';

// // assets
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// // ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

// const NavItem = ({ item, level }) => {
//   const theme = useTheme();
//   const dispatch = useDispatch();
//   const { pathname } = useLocation();
//   const customization = useSelector((state) => state.customization);
//   const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

//   const Icon = item.icon;
//   const itemIcon = item?.icon ? (
//     <Icon stroke={1.5} size="1.3rem" />
//   ) : (
//     <FiberManualRecordIcon
//       sx={{
//         width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
//         height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
//       }}
//       fontSize={level > 0 ? 'inherit' : 'medium'}
//     />
//   );

//   let itemTarget = '_self';
//   if (item.target) {
//     itemTarget = '_blank';
//   }

//   let listItemProps = {
//     component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />)
//   };
//   if (item?.external) {
//     listItemProps = { component: 'a', href: item.url, target: itemTarget };
//   }

//   const itemHandler = (id) => {
//     dispatch({ type: MENU_OPEN, id });
//     if (matchesSM) dispatch({ type: SET_MENU, opened: false });
//   };

//   // active menu item on page load
//   useEffect(() => {
//     const currentIndex = document.location.pathname
//       .toString()
//       .split('/')
//       .findIndex((id) => id === item.id);
//     if (currentIndex > -1) {
//       dispatch({ type: MENU_OPEN, id: item.id });
//     }
//     // eslint-disable-next-line
//   }, [pathname]);

//   const selected = customization.isOpen.findIndex((id) => id === item.id) > -1;

//   return (
//     <ListItemButton
//       {...listItemProps}
//       disabled={item.disabled}
//       sx={{
//         borderRadius: '15px',
//         my: 2,
//         alignItems: 'flex-start',
//         // backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
//         border: '1px solid #EFF9F9',
//         py: level > 1 ? 1 : 1.25,
//         pl: `${level * 24}px`,
//         opacity: selected ? '80%' : '20%'
//       }}
//       selected={selected}
//       onClick={() => itemHandler(item.id)}
//     >
//       <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
//       <ListItemText
//         primary={
//           <Typography variant='bodyText2' color={theme.palette.grey[0]}>
//             {item.title}
//           </Typography>
//         }
//         secondary={
//           item.caption && (
//             <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
//               {item.caption}
//             </Typography>
//           )
//         }
//       />
//       {item.chip && (
//         <Chip
//           color={item.chip.color}
//           variant={item.chip.variant}
//           size={item.chip.size}
//           label={item.chip.label}
//           avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
//         />
//       )}
//     </ListItemButton>
//   );
// };

// NavItem.propTypes = {
//   item: PropTypes.object,
//   level: PropTypes.number
// };

// export default NavItem;

import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const customization = useSelector((state) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    // Check if the icon is a function (React component) or a regular component
    typeof Icon === 'function' ? (
      <Icon style={{ width: '1.3rem', height: '1.3rem' }} />
    ) : (
      <Icon stroke={1.5} size="1.3rem" />
    )
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />),
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    dispatch({ type: MENU_OPEN, id });
    if (matchesSM) dispatch({ type: SET_MENU, opened: false });
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, [pathname]);

  const selected = customization.isOpen.findIndex((id) => id === item.id) > -1;
  console.log('selected ', selected);

  // <ListItemButton
  //     {...listItemProps}
  //     disabled={item.disabled}
  //     sx={{
  //       borderRadius: '15px',
  //       my: '20px',
  //       alignItems: 'flex-start',
  //       border: selected ?  '1px solid #EFF9F980' : '1px solid #EFF9F915',
  //       py: level > 1 ? 1 : 1.25,
  //       pl: `${level * 24}px`,
  //       // opacity: selected ? '100%' : '70%'
  //     }}
  //     selected={selected}
  //     onClick={() => itemHandler(item.id)}
  //   ></ListItemButton>
  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: '15px',
        my: '20px',
        alignItems: 'flex-start',
        border: selected ? '1px solid #EFF9F980' : '1px solid #EFF9F915',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`,
        background: selected && 'rgba(244, 123, 32, 0.03) !important',
        '&:hover': {
          backgroundColor: selected ? `rgba(244, 123, 32, 0.03) !important` : `rgba(244, 123, 32, 0.06) !important`,
        },
      }}
      selected={selected}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon sx={{ my: 'auto', mr: 2, minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="bodyText2" color={selected ? theme.palette.grey[0] : theme.palette.secondary.light}>
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
