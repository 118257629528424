import { useTheme } from '@emotion/react';
import { Box, Grid, Link, Typography } from '@mui/material';
import BackButtonWithDivider from 'components/DesignSystem/BackButtonWithDivider';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import Logo from 'components/logo';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

export default function WelcomePage() {
  const theme = useTheme();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNext = () => {
    navigate('/journey'); // Navigate to the JourneyPage
  };

  return (
    <>
      <Helmet>
        <title> Welcome | Get Will Done </title>
      </Helmet>
      <Box
        sx={{
          position: 'absolute',
          top: '2.8rem',
          left: '2rem',
          width: '16rem',
          height: '8rem',
          '@media (max-width: 600px)': {
            top: '1rem',
          },
          '@media (max-width: 1270px)': {
            top: '1rem',
            left: '5rem',
          },
        }}
      >
        <Logo />
      </Box>
      <Box
        sx={{
          // maxWidth: 800,
          height: '100%',
          margin: 'auto',
          // marginLeft:14,
          padding: 8,
          paddingTop: 0,
          paddingLeft: 3,
          paddingRight: '210px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Box sx={{ marginTop: '8.438rem' }}>
          <Box>
            <Typography variant="title1" gutterBottom sx={{ mb: '15px' }}>
              Welcome to GetWillDone!
            </Typography>
          </Box>
          <Box sx={{ mb: '60px', mt: '15px' }}>
            <Typography variant="bodyText3" color={theme.palette.grey[400]} sx={{ mb: '60px' }}>
              We are glad to be your companion on this important journey
              <Box component="span" sx={{ display: 'block' }}>
                of creating your Will.
              </Box>
            </Typography>
          </Box>
          <Box sx={{ mb: '60px' }}>
            <Typography variant="bodyText3" color={theme.palette.grey[400]} sx={{ mb: '60px' }}>
              Here, you set the pace; we simply provide the map and the
              <Box component="span" sx={{ display: 'block' }}>
                directions. A fully decked-out Dashboard will display your progress
              </Box>
              and lead you to FAQs and resourceful videos.
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="bodyText3" color={theme.palette.grey[400]}>
              As for creating your Will itself, that’s a smooth
              <Box
                component="span"
                sx={{ display: 'block', mt: 1 }} // Adjust the value of mt (margin-top) as needed
              >
                <Typography variant="heading1" component="span">
                  <Link
                    href="#"
                    sx={{
                      color: theme.palette.secondary.main, // Use theme color here
                      textDecoration: 'none',
                    }}
                  >
                    4-step process.
                  </Link>
                </Typography>
              </Box>
            </Typography>
          </Box>
          <ContainedButton1
            sx={{
              mt: '243px',
              minWidth: '125px', // Adjust this value as needed for your design
            }}
            onClick={handleNext} // Add onClick handler
          >
            <Typography variant="bodyText3">Next</Typography>
          </ContainedButton1>
        </Box>
      </Box>
    </>
  );
}
