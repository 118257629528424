import { Autocomplete, Divider, InputAdornment, List, Paper, Popper, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import Icon from '../Icon';
import InputField from '../InputField';

// Styled Paper component for dropdown
const CustomPaper = styled(Paper)(({ theme, openUpward }) => ({
    backgroundColor: theme.palette.grey[200],
    fontSize: '18px',
    fontFamily: 'DM Sans',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: openUpward
        ? '10px 10px 0 0' // Top left and right when the list opens upwards
        : '0 0 10px 10px', // Bottom left and right when the list opens downwards
}));

// Styled List component for options
const CustomList = styled(List)(({ theme }) => ({
    padding: 0,
    '& .MuiAutocomplete-option': {
        fontSize: '18px',
        fontFamily: 'DM Sans',
        color: theme.palette.grey[400],
        borderRadius: 0, // Ensure options have square corners
        '&:hover': {
            color: theme.palette.grey[600], // Hover effect
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.grey[200], // Selected effect
            color: theme.palette.grey[600], // Text color when selected
        },
    },
}));

// Custom Popper to detect placement direction using Popper.js modifiers
const CustomPopper = (props) => {
    const { onPlacementChange, ...restProps } = props;

    return (
        <Popper
            {...restProps}
            modifiers={[
                {
                    name: 'flip',
                    options: {
                        altBoundary: true,
                        fallbackPlacements: ['top'],
                    },
                },
                {
                    name: 'onUpdate',
                    enabled: true,
                    phase: 'afterWrite',
                    fn: ({ state }) => {
                        const placement = state.placement;
                        if (onPlacementChange) {
                            onPlacementChange(placement);
                        }
                    },
                },
            ]}
        />
    );
};

// Define CustomPopupIcon
const CustomPopupIcon = (props) => (
    <Icon name='down-arrow-icon.png' height={6} width={10} />
);

const CustomAutocomplete = ({
    name,
    value,
    options = [],
    placeholder,
    handleChange,
    error,
    helperText,
    onInputChange,
    onBlur,
    backgroundColor = '',
    ...props
}) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('bottom');
    const handleBackgroundColor = isEmpty(backgroundColor) ? theme.palette.grey[200] : backgroundColor;

    // Helper function to find the selected option based on value
    const getOptionByValue = (value) => {
        return options.find(option => option.value === value) || null;
    };

    // Handle blur to ensure Formik validation triggers
    const handleBlur = (event) => {
        if (onBlur) {
            onBlur(event);
        }
    };

    return (
        <Autocomplete
            value={getOptionByValue(value)}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={(event, newValue) => {
                const newValueToSet = newValue ? newValue.value : null;
                handleChange({ target: { name, value: newValueToSet } });
            }}
            onInputChange={onInputChange}
            options={options}
            onBlur={handleBlur}
            error={error}
            disablePortal
            clearIcon={false}
            PopperComponent={(popperProps) => (
                <CustomPopper
                    {...popperProps}
                    onPlacementChange={setPlacement} // Update placement state
                />
            )}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
                <InputField
                    {...params}
                    placeholder={placeholder}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    error={error}
                    helperText={helperText}
                    sx={{
                        '& .MuiInputBase-root': {
                            height: '45px',
                            borderRadius: open && placement === 'bottom'
                                ? '10px 10px 0 0'  // Conditional bottom border-radius when list opens downwards
                                : open && placement === 'top'
                                    ? '0 0 10px 10px'  // Conditional top border-radius when list opens upwards
                                    : '10px',
                            backgroundColor: handleBackgroundColor,
                        },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <CustomPopupIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            ListboxComponent={(props) => (
                <CustomList sx={{ backgroundColor: handleBackgroundColor }} {...props}>
                    {React.Children.map(props.children, (child, index) => (
                        <>
                            {React.cloneElement(child, { key: index })}
                            {index < props.children.length - 1 && (
                                <Divider
                                    key={`divider-${index}`}
                                    sx={{ borderStyle: 'dashed', borderColor: theme.palette.grey[100], width: "90%", ml: 1 }}
                                />
                            )}
                        </>
                    ))}
                </CustomList>
            )}
            PaperComponent={(props) => <CustomPaper {...props} openUpward={placement === 'top'} />}
            sx={{
                '& .MuiOutlinedInput-root': {
                    paddingRight: '12px !important',
                },
                '& .MuiAutocomplete-input': {
                    padding: '0px !important',
                    paddingLeft: '8px !important',
                    paddingRight: '0px !important',
                },
                '& .MuiAutocomplete-popupIndicator': {
                    display: 'none',
                },
            }}
            {...props}
        />
    );
};


const CustomAutocompleteFreeSolo = ({
    name,
    value,
    options = [],
    placeholder,
    handleChange,
    error,
    helperText,
    onInputChange,
    onBlur,
    backgroundColor = '',
    ...props
}) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const hasOptions = options.length > 0;
    const handleBackgroundColor = isEmpty(backgroundColor) ? theme.palette.grey[200] : backgroundColor;

    return (
        <Autocomplete
            freeSolo
            value={value}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={(event, newValue) => handleChange({ target: { name, value: newValue } })}
            onInputChange={(event, newInputValue) => {
                if (onInputChange) {
                    onInputChange({ target: { name, value: newInputValue } });
                }
            }}
            options={hasOptions ? options : []}
            forcePopupIcon={false}
            clearIcon={false}
            getOptionLabel={(option) => option.label || option || ''}
            renderInput={(params) => (
                <InputField
                    {...params}
                    placeholder={placeholder}
                    variant="outlined"
                    fullWidth
                    onBlur={onBlur}
                    error={error}
                    helperText={helperText}
                    sx={{
                        '& .MuiInputBase-root': {
                            height: '45px',
                            borderRadius: open && hasOptions ? '10px 10px 0 0' : '10px', // Conditional border-radius
                            backgroundColor: handleBackgroundColor
                        },
                    }}
                />
            )}
            sx={{
                '& .MuiAutocomplete-input': {
                    padding: '0px !important',
                    paddingLeft: '8px !important',
                    backgroundColor: handleBackgroundColor
                },
            }}
            ListboxComponent={(props) => hasOptions ? (
                <CustomList sx={{ backgroundColor: handleBackgroundColor }} {...props}>
                    {React.Children.map(props.children, (child, index) => (
                        <>
                            {React.cloneElement(child, { key: index })}
                            {index < props.children.length - 1 && (
                                <Divider
                                    key={`divider-${index}`}
                                    sx={{ borderStyle: 'dashed', borderColor: theme.palette.grey[100], width: "97%", ml: '1.5%', mr: '1.5%' }}
                                />
                            )}
                        </>
                    ))}
                </CustomList>
            ) : null}
            PaperComponent={(props) => hasOptions ? <CustomPaper {...props} /> : null}
            PopperComponent={(props) => hasOptions ? <CustomPopper {...props} /> : null}
            {...props}
        />
    );
};

export { CustomAutocomplete, CustomAutocompleteFreeSolo };
