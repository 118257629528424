import { Box, Typography, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import { ReactComponent as PrimaryDistributionIcon } from 'assets/primary-distribution-icon.svg';
import { ReactComponent as SecondaryDistributionIcon } from 'assets/secondary-distribution-icon.svg';
import { capitalCaseTransform } from 'change-case';
import FilterComponent from 'components/commonComponents/FilterComponent';
import DistributionCardModal from 'components/featureComponent/distribution/cards/DistributionCardModal';
import DistributionSkeleton from 'components/featureComponent/distribution/skeleton/DistributionSkeleton';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { fetchAssets, fetchGenerics, fetchWills } from 'services/distributionService';
import PageContainer from 'views/dashboard/PageContainer';
import PrimaryDistribution from './PrimaryDistribution';
import SecondaryDistribution from './SecondaryDistribution';

const distributions = [
    {
        type: 'primary',
        label: 'Primary Distribution',
        icon: PrimaryDistributionIcon,
    },
    {
        type: 'secondary',
        label: 'Secondary Distribution',
        icon: SecondaryDistributionIcon,
    },
];

const DistributionPage = () => {
    const theme = useTheme();
    const [selectedDistribution, setSelectedDistribution] = useState(distributions[0].type);
    const [generics, setGenerics] = useState({});
    const { fetchUser } = useContext(ContextProvider);
    const [mappings, setMappings] = useState([]);
    const [loading, setLoading] = useState(true);

    const beneficiaryNotAdded = useMemo(() => {
        return generics?.beneficiary?.length === 0;
    }, [generics]);

    const fetchGenericsData = async (genericType) => {
        setLoading(true);
        try {
            const response = await fetchGenerics(genericType);
            setGenerics((generics) => ({ ...generics, [genericType]: response.data?.generics ?? [] }));
            setLoading(false);
        } catch (err) {
            console.log(err);
        } finally {
            // setLoading(false);
        }
    };

    const fetchAssetData = async () => {
        setLoading(true);
        try {
            const response = await fetchAssets();
            setGenerics((generics) => ({ ...generics, assets: response.data?.assets ?? [] }));
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchWillsData = async () => {
        setLoading(true);
        try {
            const response = await fetchWills();
            setGenerics((generics) => ({
                ...generics,
                wills: response.data?.wills ?? [],
                scenarios: response.data?.scenarios ?? [],
            }));
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const fetchUserData = async () => {
        setLoading(true);
        try {
            const response = await fetchUser();
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        // if (user?.role === 'reviewer' && !!review) {
        //     axios.interceptors.request.use((config) => {
        //         config.headers['X-Review-Id'] = +review;
        //         return config;
        //     });
        // }
        // window.scrollTo(0, 0);
        fetchAssetData();
        fetchGenericsData('beneficiary');
        fetchWillsData();
        fetchUserData();
        // fetchData();
    }, []);

    const filteredUsers = useMemo(() => {
        return [
            ...(generics.assets ?? []),
            {
                id: null,
                information: { caption: 'Any other assets not included in the Assets that need to be distributed' },
                type: '',
                sub_type: 'Residual Assets',
            },
        ];
    }, [generics.assets])

    const normalizedWills = useMemo(() => {
        return (
            generics?.wills?.map((will) => {
                will.ScenarioId = will.ScenarioId ?? 0;
                return will;
            }) ?? []
        );
    }, [generics?.wills]);

    const mappingBalance = useMemo(() => {
        let count = 0;
        filteredUsers.forEach((row) => {
            const { id, information, } = row;
            const will = mappings?.find(({ PropertyId }) => {
                return PropertyId === id;
            });
            let value = 100;
            let total = 0;
            if (will?.distribution_type === 'value') {
                value =
                    information?.value ??
                    information?.amount ??
                    information?.insured_amount ??
                    information?.investment_amount ??
                    information?.deposited_amount;
            } else if (will?.distribution_type === 'quantity') {
                value = information?.quantity ?? information?.qty ?? information?.number;
            }
            will?.mapping?.forEach((mapping) => {
                total += +(mapping.share ?? 0);
            });
            // if (Math.abs(Number((value - total).toFixed(2))) <= 0.01) {
            //     // This will allow user to save distribution if 0.01 remaining is 0.01
            //     total = value;
            // }
            if (total !== value) {
                count += 1;
            }
        });
        return count;
    }, [filteredUsers, mappings]);

    const petMappingBalance = useMemo(() => {
        const petResult = [];
        filteredUsers.forEach((row) => {
            const { id, sub_type: subType } = row;
            if (['pets', 'car', 'two_wheeler', 'large_vehicles', 'other_vehicles'].includes(subType)) {
                const will = mappings?.find(({ PropertyId }) => {
                    return PropertyId === id;
                });
                if (will?.distribution_type === 'percentage') {
                    const hasValue100 =
                        will?.mapping?.filter((obj) => {
                            return obj.share === 100 || obj.share === '100.00';
                        }).length === 1;

                    const allOthersAreZero = will?.mapping?.every((obj) => {
                        return obj.share === 0 || obj.share === 100 || obj.share === '100.00';
                    });

                    if (hasValue100 && allOthersAreZero) {
                        petResult.push(true);
                    } else {
                        petResult.push(false);
                    }
                }
            }
        });
        return petResult;
    }, [filteredUsers, mappings]);

    return (
        <>
            <Helmet>
                <title> My Distribution | Get Will Done </title>
            </Helmet>
            <PageContainer defaultFaqType="distribution">
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant="title1">My Distribution</Typography>
                        <Typography
                            variant="bodyText3"
                            color={theme.palette.grey[400]}
                            display={'inline-block'}
                            maxWidth={'100%'}
                            width={'44.563rem'}
                        >
                            Who gets to inherit your assets is an intimate choice. Take as much time as you need to make this
                            important decision.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '15px', mt: '20px' }}>
                        {distributions.map((distribution) => (
                            <DistributionCardModal
                                key={distribution.type}
                                label={distribution.label}
                                icon={distribution.icon}
                                isSelected={selectedDistribution === distribution.type}
                                onSelect={() => setSelectedDistribution(distribution.type)}
                            />
                        ))}
                    </Box>
                    <Box sx={{ marginTop: '50px', paddingRight: '75px' }}>
                        <FilterComponent title={`${capitalCaseTransform(selectedDistribution)} Distribution`} hideFilter />
                    </Box>
                    {loading ? (
                        <Box padding={'24px 0'} paddingRight={'75px'}>
                            <DistributionSkeleton isPrimary={selectedDistribution === 'primary'} />
                        </Box>
                    ) : (
                        <Box sx={{ width: '100%' }}>
                            {beneficiaryNotAdded ? (
                                <Box sx={{ marginTop: '24px' }}>
                                    <Typography color={theme.palette.grey[400]} variant="bodyText3">
                                        Add Beneficiary to Distribute the Asset's.
                                    </Typography>
                                </Box>
                            ) : selectedDistribution === 'primary' ? (
                                <PrimaryDistribution
                                    filteredUsers={filteredUsers}
                                    normalizedWills={normalizedWills}
                                    mappings={mappings}
                                    generics={generics}
                                    setMappings={setMappings}
                                    mappingBalance={mappingBalance}
                                    petMappingBalance={petMappingBalance}
                                    fetchWillsData={fetchWillsData}
                                    loading={loading}
                                    setLoading={setLoading}                                />
                            ) : (
                                <SecondaryDistribution
                                    filteredUsers={filteredUsers}
                                    normalizedWills={normalizedWills}
                                    mappings={mappings}
                                    generics={generics}
                                    setMappings={setMappings}
                                    mappingBalance={mappingBalance}
                                    petMappingBalance={petMappingBalance}
                                    setGenerics={setGenerics}
                                    loading={loading}
                                    setLoading={setLoading}
                                />
                            )}
                        </Box>
                    )}
                </>
            </PageContainer>
        </>
    );
};

export default DistributionPage;
