import * as Yup from 'yup';
import formValidation from './formValidations';

const bankAccountTypes = [{
    "value": "Savings",
    "label": "Savings"
}, {
    "value": "Current",
    "label": "Current"
}, {
    "value": "Recurring",
    "label": "Recurring"
}]

const petTypes = [{
    "value": "Dog",
    "label": "Dog"
}, {
    "value": "Cat",
    "label": "Cat"
}, {
    "value": "Fish",
    "label": "Fish"
}, {
    "value": "Tortoise",
    "label": "Tortoise"
}, {
    "value": "Horse",
    "label": "Horse"
}, {
    "value": "Birds",
    "label": "Birds"
}]

const liveStockTypes = [
    {
        "value": "Cow",
        "label": "Cow"
    },
    {
        "value": "Chickens",
        "label": "Chickens"
    },
    {
        "value": "Sheep",
        "label": "Sheep"
    }
]

export const assetSubTypes = {

    // ========================================
    // Asset SubTypes commercial
    // ========================================
    commercial: [
        {
            value: 'shop',
            label: 'Shop',
            inputFields: [
                { type: 'text', name: 'description_or_name', placeholder: 'Description/Name*', validation: formValidation.inputTextValidation.required('Required field') },
                { type: 'text', name: 'address', placeholder: 'Address*', validation: Yup.string().required('Required field') },
                { type: 'number', name: 'area_sq_ft', placeholder: 'Area (Sq. Ft)', validation: formValidation.numberValidation },
                { type: 'dropDown', name: 'joint_owner_name', placeholder: 'Joint Owner Name' },
                { type: 'dropDown', name: 'nominee', placeholder: 'Nominee' },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' }
            ]
        },
        {
            value: 'office',
            label: 'Office',
            inputFields: [
                { type: 'text', name: 'description_or_name', placeholder: 'Description/Name*', validation: formValidation.inputTextValidation.required('Required field') },
                { type: 'text', name: 'address', placeholder: 'Address*', validation: Yup.string().required('Required field') },
                { type: 'number', name: 'area_sq_ft', placeholder: 'Area (Sq. Ft)', validation: formValidation.numberValidation },
                { type: 'dropDown', name: 'joint_owner_name', placeholder: 'Joint Owner Name' },
                { type: 'dropDown', name: 'nominee', placeholder: 'Nominee' },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' }
            ]
        },
        {
            value: 'factory',
            label: 'Factory',
            inputFields: [
                { type: 'text', name: 'description_or_name', placeholder: 'Description/Name*', validation: formValidation.inputTextValidation.required('Required field') },
                { type: 'text', name: 'address', placeholder: 'Address*', validation: Yup.string().required('Required field') },
                { type: 'number', name: 'area_sq_ft', placeholder: 'Area (Sq. Ft)', validation: formValidation.numberValidation },
                { type: 'dropDown', name: 'joint_owner_name', placeholder: 'Joint Owner Name' },
                { type: 'dropDown', name: 'nominee', placeholder: 'Nominee' },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' }
            ]
        },
        {
            value: 'godown',
            label: 'Godown',
            inputFields: [
                { type: 'text', name: 'description_or_name', placeholder: 'Description/Name*', validation: formValidation.inputTextValidation.required('Required field') },
                { type: 'text', name: 'address', placeholder: 'Address*', validation: Yup.string().required('Required field') },
                { type: 'number', name: 'area_sq_ft', placeholder: 'Area (Sq. Ft)', validation: formValidation.numberValidation },
                { type: 'dropDown', name: 'joint_owner_name', placeholder: 'Joint Owner Name' },
                { type: 'dropDown', name: 'nominee', placeholder: 'Nominee' },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes residential
    // ========================================
    residential: [
        {
            value: 'bungalow',
            label: 'Bungalow',
            inputFields: [
                { type: 'text', name: 'description_or_name', placeholder: 'Description/Name*', validation: formValidation.inputTextValidation.required('Required field') },
                { type: 'text', name: 'address', placeholder: 'Address*', validation: Yup.string().required('Required field') },
                { type: 'number', name: 'area_sq_ft', placeholder: 'Area (Sq. Ft)', validation: formValidation.numberValidation },
                { type: 'dropDown', name: 'joint_owner_name', placeholder: 'Joint Owner Name' },
                { type: 'dropDown', name: 'nominee', placeholder: 'Nominee' },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' }
            ]
        },
        {
            value: 'flat_or_apartment',
            label: 'Flat/Apartment',
            inputFields: [
                { type: 'text', name: 'description_or_name', placeholder: 'Description/Name*', validation: formValidation.inputTextValidation.required('Required field') },
                { type: 'text', name: 'address', placeholder: 'Address*', validation: Yup.string().required('Required field') },
                { type: 'number', name: 'area_sq_ft', placeholder: 'Area (Sq. Ft)', validation: formValidation.numberValidation },
                { type: 'dropDown', name: 'joint_owner_name', placeholder: 'Joint Owner Name' },
                { type: 'dropDown', name: 'nominee', placeholder: 'Nominee' },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes plot
    // ========================================
    plot: [
        {
            value: 'land',
            label: 'Land',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*", validation: formValidation.inputTextValidation },
                { type: "text", name: "address", placeholder: "Address line 1*", validation: Yup.string().required('Required field') },
                { type: "text", name: "address_2", placeholder: "Address line 2" },
                { type: "number", name: "area_sq_ft", placeholder: "Area (Sq. Ft)*", validation: formValidation.numberValidation.required('Required field') },
                { type: "dropDown", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes bankAccounts
    // ========================================
    bank_account: [
        {
            value: 'fixed_deposit',
            label: 'Fixed Deposit',
            inputFields: [
                { type: "dropDown", name: "bank_name", placeholder: "Bank Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "branch_name", placeholder: "Branch Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "account_no", placeholder: "Account Number/FD Number*", validation: formValidation.fdNumberValidation },
                { type: "text", name: "address", placeholder: "Address*", validation: Yup.string().required('Required field') },
                { type: "number", name: "deposited_amount", placeholder: "Deposited Amount" },
                { type: "dropDown", name: "joint_holder_name", placeholder: "Joint Holder Name*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'savings_account',
            label: 'Savings Account',
            inputFields: [
                { type: "dropDown", name: "bank_name", placeholder: "Bank Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "branch_name", placeholder: "Branch Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "account_no", placeholder: "Account Number*", validation: formValidation.bankAccountNoValidation },
                { type: "text", name: "address", placeholder: "Address*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "account_type", placeholder: "Account Type*", options: bankAccountTypes, validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "joint_holder_name", placeholder: "Joint Holder Name*", validation: Yup.string().required('Required field') },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" }
            ]
        },
        {
            value: 'recurring_deposit',
            label: 'Recurring Deposit',
            inputFields: [
                { type: "dropDown", name: "bank_name", placeholder: "Bank Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "branch_name", placeholder: "Branch Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "address", placeholder: "Address*", validation: Yup.string().required('Required field') },
                { type: "text", name: "account_no", placeholder: "Account Number*", validation: formValidation.bankAccountNoValidation },
                { type: "dropDown", name: "joint_holder_name", placeholder: "Joint Holder Name*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'current_account',
            label: 'Current Account',
            inputFields: [
                { type: "dropDown", name: "bank_name", placeholder: "Bank Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "branch_name", placeholder: "Branch Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "account_no", placeholder: "Account Number*", validation: formValidation.bankAccountNoValidation },
                { type: "text", name: "address", placeholder: "Address*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "account_type", placeholder: "Account Type*", options: bankAccountTypes, validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "joint_holder_name", placeholder: "Joint Holder Name*", validation: Yup.string().required('Required field') },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes listedSecurities
    // ========================================
    listed_securities: [
        {
            value: 'demat_account',
            label: 'Demat Account',
            inputFields: [
                { type: "text", name: "depository_participant_name", placeholder: "Depository Participant Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "dp_id", placeholder: "DP ID*", validation: formValidation.dpIdValidation },
                { type: "text", name: "client_id", placeholder: "Client ID*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "text", name: "nsdl_id", placeholder: "NSDL ID" },
                { type: "text", name: "nsdl_email", placeholder: "Email ID", validation: formValidation.emailValidation },
                { type: "text", name: "csdl_id", placeholder: "CSDL ID" },
                { type: "text", name: "csdl_email", placeholder: "Email ID", validation: formValidation.emailValidation }
            ]
        },
        {
            value: 'physical_shares',
            label: 'Physical Shares',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Company Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "number", name: "no_of_shares", placeholder: "Number of Shares*", validation: formValidation.numberValidation.required('Required field') },
                { type: "text", name: "share_certificate_no", placeholder: "Share Certificate Number*", validation: formValidation.shareCertificateNoValidation.required('Required field') },
                { type: "text", name: "folio_no", placeholder: "Folio Number", validation: formValidation.folioNumberValidation },
                { type: "dropDown", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'mutual_funds',
            label: 'Mutual Funds',
            inputFields: [
                { type: "text", name: "fund_name", placeholder: "Fund Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "folio_no", placeholder: "Folio Number*", validation: formValidation.folioNumberValidation.required('Required field') },
                { type: "dropDown", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'portfolio_management_services',
            label: 'Portfolio Management Services',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Company Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "account_no_or_client_id", placeholder: "Account Number/Client ID*", validation: formValidation.accountNoValidation.required('Required field') },
                { type: "dropDown", name: "joint_owner_name", placeholder: "Joint Owner Name*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "contact_person_name", placeholder: "Contact Person Name" },
                { type: "text", name: "contactPersonPhoneNumber", placeholder: "Contact Person Phone Number" },
                { type: "text", name: "address", placeholder: "Address" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'bonds',
            label: 'Bonds',
            inputFields: [
                { type: "text", name: "bond_name", placeholder: "Bond Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "certificate_no", placeholder: "Certificate Number*", validation: formValidation.certificateNoValidation.required('Required field') },
                { type: "number", name: "number_of_units", placeholder: "Number of Units*", validation: formValidation.numberValidation.required('Required field') },
                { type: "dropDown", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'invit_funds',
            label: 'INVITs',
            inputFields: [
                { type: "text", name: "fund_name", placeholder: "Fund Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "folio_no", placeholder: "Folio Number*", validation: formValidation.folioNumberValidation.required('Required field') },
                { type: "number", name: "number_of_units", placeholder: "Number of Units*", validation: formValidation.numberValidation.required('Required field') },
                { type: "dropDown", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'reits',
            label: 'REITs',
            inputFields: [
                { type: "text", name: "fund_name", placeholder: "Fund Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "folio_no", placeholder: "Folio Number*", validation: formValidation.folioNumberValidation.required('Required field') },
                { type: "number", name: "number_of_units", placeholder: "Number of Units*", validation: formValidation.numberValidation.required('Required field') },
                { type: "dropDown", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'other_securities',
            label: 'Other Securities',
            inputFields: [
                { type: "text", name: "description_of_investment", placeholder: "Description of Investment*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "unique_identification_no", placeholder: "Unique Identification No.*", validation: formValidation.uinNoValidation.required('Required field') },
                { type: "dropDown", name: "joint_holder_name", placeholder: "Joint Account Holder" },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes governmentInvestment
    // ========================================
    government_investment: [
        {
            value: 'national_savings_certificate',
            label: 'National Savings Certificate (NSC)',
            inputFields: [
                { type: "text", name: "certificate_no", placeholder: "Certificate Number*", validation: formValidation.certificateNoValidation.required('Required field') },
                { type: "number", name: "investment_amount", placeholder: "Investment Amount*", validation: formValidation.numberValidation.required('Required field') },
                { type: "date", name: "maturity_date", placeholder: "Maturity Date", validation: formValidation.maturityDateValidation },
                { type: "dropDown", name: "joint_holder_name", placeholder: "Joint Holder Name*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'kisan_vikas_patra',
            label: 'Kisan Vikas Patra (KVP)',
            inputFields: [
                { type: "text", name: "certificate_no", placeholder: "Certificate Number*", validation: formValidation.certificateNoValidation.required('Required field') },
                { type: "number", name: "investment_amount", placeholder: "Investment Amount*", validation: formValidation.numberValidation.required('Required field') },
                { type: "date", name: "maturity_date", placeholder: "Maturity Date", validation: formValidation.maturityDateValidation },
                { type: "dropDown", name: "joint_holder_name", placeholder: "Joint Holder Name*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }, {
            value: 'national_pension_scheme',
            label: 'National Pension Scheme (NPS)',
            inputFields: [
                { type: "text", name: "prn_no", placeholder: "PRN Number*", validation: formValidation.prnNoValidation.required('Required field') },
                { type: "number", name: "investment_amount", placeholder: "Investment Amount*", validation: formValidation.numberValidation.required('Required field') },
                { type: "date", name: "maturity_date", placeholder: "Maturity Date", validation: formValidation.maturityDateValidation },
                { type: "dropDown", name: "joint_holder_name", placeholder: "Joint Holder Name*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }, {
            value: 'ppf',
            label: 'PPF',
            inputFields: [
                { type: "dropDown", name: "bank_name", placeholder: "Bank Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "branch_name", placeholder: "Branch Name*", validation: Yup.string().required('Required field') },
                { type: "text", name: "account_no", placeholder: "Account Number*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Unlisted Securities
    // ========================================
    unlisted_securities: [
        {
            value: 'demat_account',
            label: 'Demat Account',
            inputFields: [
                { type: "text", name: "depository_participant_name", placeholder: "Depository Participant Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "dp_id", placeholder: "DP ID*", validation: formValidation.dpIdValidation },
                { type: "text", name: "client_id", placeholder: "Client ID*", validation: formValidation.clientIdValidation.required('Required field') },
                { type: "dropDown", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "text", name: "nsdl_id", placeholder: "NSDL ID" },
                { type: "text", name: "nsdl_email", placeholder: "Email ID", validation: formValidation.emailValidation },
                { type: "text", name: "csdl_id", placeholder: "CSDL ID" },
                { type: "text", name: "csdl_email", placeholder: "Email ID", validation: formValidation.emailValidation }
            ]
        },
        {
            value: 'interest_in_llp',
            label: 'Interest in LLP',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Company Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "number", name: "interest", placeholder: "Interest*", validation: formValidation.numberValidation.required('Required field') },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' },
            ]
        },
        {
            value: 'interest_in_partnership_firm',
            label: 'Interest in Partnership Firm',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Company Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "number", name: "interest", placeholder: "Interest*", validation: formValidation.numberValidation.required('Required field') },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' },
            ]
        },
        {
            value: 'shares_in_private_limited_company',
            label: 'Shares in Private Limited Company',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Company Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "number", name: "no_of_shares", placeholder: "Number of shares*", validation: formValidation.numberValidation.required('Required field') },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' },
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Ornaments
    // ========================================
    ornaments: [
        {
            value: 'gold',
            label: 'Gold',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "number", name: "quantity", placeholder: "Quantity*", validation: formValidation.numberValidation.required('Required field') },
                { type: "number", name: "weight", placeholder: "Weight (grams)" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'silver',
            label: 'Silver',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "number", name: "quantity", placeholder: "Quantity*", validation: formValidation.numberValidation.required('Required field') },
                { type: "number", name: "weight", placeholder: "Weight (grams)" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'jewellery',
            label: 'Jewellery',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "number", name: "quantity", placeholder: "Quantity*", validation: formValidation.numberValidation.required('Required field') },
                { type: "number", name: "weight", placeholder: "Weight (grams)" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'silver_utensils',
            label: 'Silver Utensils',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "number", name: "quantity", placeholder: "Quantity*", validation: formValidation.numberValidation.required('Required field') },
                { type: "number", name: "weight", placeholder: "Weight (grams)" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'precious_stones_or_collectables',
            label: 'Precious Stones/Collectibles',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "number", name: "quantity", placeholder: "Quantity*", validation: formValidation.numberValidation.required('Required field') },
                { type: "number", name: "weight", placeholder: "Weight (grams)" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes vehicles
    // ========================================
    vehicles: [
        {
            value: 'car',
            label: 'Car',
            inputFields: [
                { type: "text", name: "car_make", placeholder: "Make of Vehicle*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "license_plate_no", placeholder: "License Plate number*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "description_of_vehicle", placeholder: "Description of Vehicle" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                // { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'two_wheeler',
            label: 'Two wheeler',
            inputFields: [
                { type: "text", name: "two_wheeler_make", placeholder: "Make of Vehicle*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "license_plate_no", placeholder: "License Plate number*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "description_of_vehicle", placeholder: "Description of Vehicle" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                // { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'large_vehicles',
            label: 'Large vehicles',
            inputFields: [
                { type: "text", name: "vehicle_make", placeholder: "Make of Vehicle*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "license_plate_no", placeholder: "License Plate number*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "description_of_vehicle", placeholder: "Description of Vehicle" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                // { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'other_vehicles',
            label: 'Other Vehicles',
            inputFields: [
                { type: "text", name: "make", placeholder: "Make of Vehicle*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "type_of_vehicle", placeholder: "Type of Vehicle" },
                { type: "text", name: "license_plate_no", placeholder: "License Plate number*", validation: Yup.string().required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "description_of_vehicle", placeholder: "Description of Vehicle" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                // { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Pets
    // ========================================
    pets: [
        {
            value: 'pets',
            label: 'Pets',
            inputFields: [
                { type: "dropDown", name: "type_of_pet", placeholder: "Type of Pet*", options: petTypes, validation: Yup.string().required('Required field') },
                { type: "text", name: "breed", placeholder: "Breed" },
                { type: "text", name: "name_of_pet", placeholder: "Name of Pet*", validation: Yup.string().required('Required field') },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'livestock',
            label: 'Livestock',
            inputFields: [
                { type: "dropDown", name: "type_of_pet", placeholder: "Type of Livestock*", options: liveStockTypes, validation: Yup.string().required('Required field') },
                { type: "number", name: "number", placeholder: "Number*", validation: formValidation.numberValidation.required('Required field') },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Art
    // ========================================
    art: [
        {
            value: 'art',
            label: 'Art',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "number", name: "quantity", placeholder: "Quantity*", validation: formValidation.numberValidation.required('Required field') },
                { type: "number", name: "weight", placeholder: "Weight (grams)" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'sculptures',
            label: 'Sculptures',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "number", name: "quantity", placeholder: "Quantity*", validation: formValidation.numberValidation.required('Required field') },
                { type: "number", name: "weight", placeholder: "Weight (grams)" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'paintings',
            label: 'Paintings',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "number", name: "quantity", placeholder: "Quantity*", validation: formValidation.numberValidation.required('Required field') },
                { type: "number", name: "weight", placeholder: "Weight (grams)" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'miscellaneous',
            label: 'Miscellaneous',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "number", name: "quantity", placeholder: "Quantity*", validation: formValidation.numberValidation.required('Required field') },
                { type: "number", name: "weight", placeholder: "Weight (grams)" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "add_picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Post Retiral
    // ========================================
    post_retiral: [
        {
            value: 'gratuity',
            label: 'Gratuity',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Name of the Company Where Employed*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'epf',
            label: 'EPF',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Name of the Company Where Employed*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "uan_no", placeholder: "UAN Number*", validation: formValidation.uanNoValidation.required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'superannuation',
            label: 'Superannuation',
            inputFields: [
                { type: "text", name: "name_of_the_company_where_employed", placeholder: "Name of the Company Where Employed*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "superannuation_number", placeholder: "Superannuation Number" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'senior_citizen_saving_scheme',
            label: 'Senior Citizen Saving Scheme',
            inputFields: [
                { type: "text", name: "scheme_name", placeholder: "Name of the Scheme*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "uan_no", placeholder: "UAN Number*", validation: formValidation.uanNoValidation.required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'monthly_pension_schemes',
            label: 'Monthly Pension Schemes',
            inputFields: [
                { type: "text", name: "name_of_insurance_company", placeholder: "Name of Insurance Company*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "insurance_account_no", placeholder: "Insurance Account Number*", validation: formValidation.insuranceAccountNoValidation.required('Required field') },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Insurances
    // ========================================
    insurances: [
        {
            value: 'term_insurance',
            label: 'Term Insurance',
            inputFields: [
                { type: "text", name: "insurance_company_name", placeholder: "Insurance Company Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "policy_no", placeholder: "Policy Number*", validation: formValidation.policyNoValidation.required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'accident_insurance',
            label: 'Accident Insurance',
            inputFields: [
                { type: "text", name: "insurance_company_name", placeholder: "Insurance Company Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "policy_no", placeholder: "Policy Number*", validation: formValidation.policyNoValidation.required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'money_back_policies',
            label: 'Money Back Policies',
            inputFields: [
                { type: "text", name: "insurance_company_name", placeholder: "Insurance Company Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "policy_no", placeholder: "Policy Number*", validation: formValidation.policyNoValidation.required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'other_insurance_policies',
            label: 'Other Insurance Policies',
            inputFields: [
                { type: "text", name: "insurance_company_name", placeholder: "Insurance Company Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "policy_no", placeholder: "Policy Number*", validation: formValidation.policyNoValidation.required('Required field') },
                { type: "dropDown", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Digital Assets
    // ========================================
    digital_assets: [
        {
            value: 'crypto_currency',
            label: 'Cryptocurrency',
            inputFields: [
                { type: "text", name: "type_of_asset", placeholder: "Type of Asset*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "broker_or_platform", placeholder: "Broker/Platform*", validation: Yup.string().required('Required field') },
                { type: "number", name: "units", placeholder: "Units", validation: formValidation.numberValidation },
                { type: "text", name: "username", placeholder: "Username", },
                { type: "text", name: "password", placeholder: "Password", }
            ]
        },
        {
            value: 'nfts',
            label: 'NFTs',
            inputFields: [
                { type: "text", name: "type_of_asset", placeholder: "Type of Asset*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "broker_or_platform", placeholder: "Broker/Platform*", validation: Yup.string().required('Required field') },
                { type: "number", name: "units", placeholder: "Units", validation: formValidation.numberValidation },
                { type: "text", name: "username", placeholder: "Username" },
                { type: "text", name: "password", placeholder: "Password" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Intellectual Property Rights
    // ========================================
    intellectual_property_rights: [
        {
            value: 'patents',
            label: 'Patents',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "registration_no", placeholder: "Patent Registration Number/ Application Number*", validation: formValidation.patentRegistrationNoValidation.required('Required field') },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'trademark',
            label: 'Trademark',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "registration_no", placeholder: "Trademark Registration Number/ Application Number*", validation: formValidation.trademarkRegistrationNoValidation.required('Required field') },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'copyright',
            label: 'Copyright',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "registration_no", placeholder: "Copyright Registration Number/Application Number*", validation: formValidation.copyrightRegistrationNoValidation.required('Required field') },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'domain_name',
            label: 'Domain Name',
            inputFields: [
                { type: "text", name: "name", placeholder: "Domain Name*", validation: formValidation.domainValidation.required('Required field') },
                { type: "text", name: "registrar_name", placeholder: "Registrar Name" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Other Assets
    // ========================================
    other_assets: [
        {
            value: 'other_assets',
            label: 'Other Assets',
            inputFields: [
                { type: "text", name: "name", placeholder: "Name/Type of Asset*", validation: formValidation.inputTextValidation.required('Required field') },
                { type: "text", name: "details", placeholder: "Details of Asset*", validation: Yup.string().required('Required field') }
            ]
        }
    ]
};  