import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Chart } from 'chart.js';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
// import { fDate } from '../../utils/formatTime';
import { fDate } from 'utils/formatTime';
import { matchDataWithDateRange } from './utils/filterDate';

const timeRangeOptions = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 Days', value: 'last7days' },
  { label: 'Last 30 Days', value: 'last30days' },
  { label: 'This Month', value: 'thismonth' },
  { label: 'Custom Range', value: 'custom' },
];

function RevenueTrendsCart() {
  const [timeRange, setTimeRange] = useState('today');
  const [chart, setChart] = useState(null);
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      fromDate: '',
      toDate: '',
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date()
        .typeError('Please enter valid date dd-mm-yyyy format')
        .min(dayjs('1900-01-01'), 'Date must be greater than 1900-01-01')
        .max(dayjs().endOf('day'), 'Date must be equal or before today'),
      toDate: Yup.date()
        .typeError('Please enter valid date dd-mm-yyyy format')
        .min(dayjs('1900-01-01'), 'Date must be greater than 1900-01-01')
        .max(dayjs().endOf('day'), 'Date must be equal or before today')
        .test({
          name: 'notBeforeAsFromDate',
          message: 'To Date must be equal or greater than from date',
          test: (toDate, values) => {
            const fromDate = values.parent.fromDate;
            return toDate !== null ? dayjs(fromDate).isSameOrBefore(dayjs(toDate), 'day') : false;
          },
        }),
    }),
    onSubmit: (values) => {
      fetchData(values);
    },
  });

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
    formik.setValues({
      fromDate: '',
      toDate: '',
    });
  };

  const fetchData = (dateValues) => {
    const startDate = fDate(dateValues?.fromDate?.$d, 'yyyy-MM-dd');
    const endDate = fDate(dateValues?.toDate?.$d, 'yyyy-MM-dd');

    let url = `/admin/revenue-trends?filter=${timeRange}`;
    if (timeRange === 'custom') {
      url += `&startDate=${startDate}&endDate=${endDate}`;
    }
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const updatedData = matchDataWithDateRange(startDate, endDate, data, timeRange, 'totalRevenue'); // totalRevenue is a key accessor for the counts.
        const labels = updatedData.map((item) => item.date); // replace dummyData with data
        const counts = updatedData.map((item) => item.totalRevenue);

        const ctx = document.getElementById('revenue-trend-chart').getContext('2d');

        if (chart) {
          chart.destroy(); // Destroy the previous chart instance
        }

        const newChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels,
            datasets: [
              {
                label: 'Revenue',
                data: counts,
                fill: false,
                borderColor: theme.palette.primary.main,
                tension: 0.1,
              },
            ],
          },
          options: {
            aspectRatio: 3.18, // change aspect ratio to change the map dimentions
            scales: {
              y: {
                // max: 5,
                min: 0,
                ticks: {
                  stepSize: 1,
                },
              },
            },
          },
        });

        setChart(newChart);
      })
      .catch((error) => console.error('Error fetching data:', error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFilterClick = () => {
    formik.handleSubmit();
  };

  const handleDateChange = (value, name) => {
    const event = {
      target: {
        name,
        value: value !== null ? dayjs(value) : null,
      },
    };
    formik.handleChange(event);
  };

  return (
    <Card sx={{ padding: '28px' }}>
      <Typography variant="h4" marginBottom="8px">
        Revenue Trends
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          margin: '16px 0',
          justifyContent: 'flex-start',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <FormControl variant="outlined" size="small" sx={{ minWidth: '120px' }}>
          <InputLabel id="time-range-select-label">Time Range</InputLabel>
          <Select
            value={timeRange}
            onChange={handleTimeRangeChange}
            label="Time Range"
            labelId="time-range-select-label"
            sx={{
              '&.MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#f15d27 !important', // Border color when not focused
                },
                '&:hover fieldset': {
                  borderColor: '#f15d27 !important', // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#f15d27 !important', // Border color when focused
                },
              },
              '&.MuiSelect-icon': {
                color: '#f15d27', // Icon color
              },
              '&.MuiInputLabel-root': {
                color: '#f15d27', // Label color
              },
            }}
          >
            {timeRangeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {timeRange === 'custom' && (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <FormControl required error={formik.errors.fromDate} fullWidth variant="standard">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="From Date"
                  value={formik.values.fromDate}
                  name="fromDate"
                  inputFormat={'dd-MM-yyyy'}
                  onChange={(value) => handleDateChange(value, 'fromDate')}
                  maxDate={dayjs()}
                  error={formik.errors.fromDate}
                  onBlur={formik.handleBlur}
                  maxDateMessage="Past dates are not allowed"
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#f15d27',
                          },
                          '&:hover fieldset': {
                            borderColor: 'red',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#f15d27',
                          },
                        },
                        '& .MuiInputLabel-outlined': {
                          color: '#f15d27',
                        },
                        svg: { color: '#f15d27' },
                        input: { color: '#f15d27' },
                        label: { color: '#f15d27' },
                      }}
                      {...params}
                      placeholder={'DD-MM-YYYY'}
                    />
                  )}
                />
                {formik.touched.fromDate && formik.errors.fromDate && (
                  <FormHelperText error id={`standard-weight-helper-text-fromDate`}>
                    {formik.errors.fromDate}
                  </FormHelperText>
                )}
              </LocalizationProvider>
            </FormControl>
            <FormControl required error={formik.errors.toDate} fullWidth variant="standard">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="To Date"
                  value={formik.values.toDate}
                  name="toDate"
                  inputFormat={'dd-MM-yyyy'}
                  onChange={(value) => handleDateChange(value, 'toDate')}
                  maxDate={dayjs()}
                  error={formik.errors.toDate}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#f15d27',
                          },
                          '&:hover fieldset': {
                            borderColor: 'red',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#f15d27',
                          },
                        },
                        '& .MuiInputLabel-outlined': {
                          color: '#f15d27',
                        },
                        svg: { color: '#f15d27' },
                        input: { color: '#f15d27' },
                        label: { color: '#f15d27' },
                      }}
                      {...params}
                      placeholder={'DD-MM-YYYY'}
                    />
                  )}
                />
                {formik.touched.toDate && formik.errors.toDate && (
                  <FormHelperText error id={`standard-weight-helper-text-toDate`}>
                    {formik.errors.toDate}
                  </FormHelperText>
                )}
              </LocalizationProvider>
            </FormControl>
          </Box>
        )}
        <Tooltip title={`Apply Filter`}>
          <Button variant="outlined" sx={{ width: '10vw' }} onClick={handleFilterClick}>
            Apply Filter
          </Button>
        </Tooltip>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <canvas id="revenue-trend-chart" />
      </Box>
    </Card>
  );
}

export default RevenueTrendsCart;
